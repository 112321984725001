import AddIcon from "@app/icons/add.svg?react";
import type { Dropdown, DropdownProps } from "primereact/dropdown";
import {
	type ReactNode,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { FieldErrors, useForm } from "react-hook-form";

import { Button } from "@app/components/button";
import {
	FormBuilder,
	type FormBuilderProps,
} from "@app/components/form-builder";
import { IconButton } from "@app/components/icon-button";
import { Typography } from "@app/components/typography";
import type {
	DropdownOption,
	WithdrawalDetails,
	WithdrawalForm,
} from "@app/entities";

import { Dialog } from "@app/components/dialog";
import { MoreInfoTooltip } from "@app/components/more-info-tooltip";
import { Note } from "@app/components/note";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { FiCheck, FiPlus } from "react-icons/fi";
import { twMerge } from "tailwind-merge";
import styles from "./index.module.css";

import "./withdraw-modal.css";
import { useClients } from "@app/hooks/use-clients";
import { removeNumberFormatting } from "@app/utils/format-number-string";
import { useWithdrawal } from "./use-withdrawal";

const splitter = "___SPLITTER____";
const DEFAULT_FEE = "ZAR 20.00";
const DEFAULT_WITHDRAW_TYPE = "standard";

export const WithdrawModal = (props: {
	isOpen: boolean;
	resetValues: boolean;
	onAddBankAccount: () => void;
	onClose: () => void;
	onManageAccounts: () => void;
	onSubmit: (withdrawalDetails: WithdrawalDetails) => void;
	onResetErrors?: () => void;
	initialErrors?: FieldErrors<WithdrawalForm>;
}) => {
	const dropdownRef = useRef<Dropdown>(null);
	const [isFocused, setIsFocused] = useState(false);
	const isMobile = useMediaQuery();

	const { activeClientId } = useClients();
	const { backAccounts, withdrawOptions, validateRequest } = useWithdrawal();

	const {
		control,
		handleSubmit,
		setValue,
		watch,
		reset,
		clearErrors,
		setError,
		formState: { errors },
	} = useForm<WithdrawalForm>({
		errors: props.initialErrors,
		defaultValues: {
			withdrawalType: DEFAULT_WITHDRAW_TYPE,
		},
		mode: "onChange",
	});

	const handleIsValidRequest = async () => {
		const selectedBank = backAccounts?.find(
			(item) => item.account_number === watch("bankAccount"),
		);
		const amount = watch("amount");
		const errors = await validateRequest({
			client_id: activeClientId,
			bank_id: selectedBank?.id,
			amount: amount
				? Number.parseFloat(removeNumberFormatting(amount))
				: undefined,
			withdrawal_type: DEFAULT_WITHDRAW_TYPE,
		});

		if (errors) {
			errors.fieldErrors.forEach((error) => {
				setError(error.name as any, { message: error.message });
			});
			return false;
		}
		props.onResetErrors?.();
		clearErrors();
		return true;
	};

	const handleChangeAmount = (value: string) => {
		const amount = removeNumberFormatting(value);
		if (!!amount && !Number.isNaN(Number(amount))) {
			setValue("amount", Number.parseFloat(amount).toLocaleString("en"), {
				shouldTouch: true,
			});
		} else {
			setValue("amount", "", { shouldTouch: true });
		}
		handleIsValidRequest();
	};

	const bankAccountOptions = useMemo(() => {
		if (backAccounts) {
			return backAccounts.map((account) => {
				if (account.bank && account.account_number) {
					return {
						value: account.account_number,
						label: account.bank + splitter + account.account_number,
					};
				}
				return { label: "", value: "" };
			});
		}
		return [{ label: "", value: "" }];
	}, [backAccounts]);

	const bankAccountItemTemplate = (
		option: DropdownOption,
		value: any,
	): ReactNode => {
		const displayOptions = option.label.split(splitter);

		if (option.value === (value as string)) {
			return (
				<div className="withdraw-modal-bank-account-dropdown-row">
					<div className="withdraw-modal-bank-account-dropdown-values">
						<Typography
							className="withdraw-modal-bank-account-dropdown-value"
							theme="textMd"
						>
							{displayOptions[0]}
						</Typography>
						<Typography
							className="withdraw-modal-bank-account-dropdown-value-label"
							theme="textMd"
						>
							{displayOptions[1]}
						</Typography>
					</div>
					<FiCheck size={20} color="#888" />
				</div>
			);
		}

		return (
			<div className="withdraw-modal-bank-account-dropdown-row">
				<div className="withdraw-modal-bank-account-dropdown-values">
					<Typography
						className="withdraw-modal-bank-account-dropdown-value"
						theme="textMd"
					>
						{displayOptions[0]}
					</Typography>
					<Typography
						className="withdraw-modal-bank-account-dropdown-value-label"
						theme="textMd"
					>
						{displayOptions[1]}
					</Typography>
				</div>
			</div>
		);
	};

	const resetValues = useCallback(() => {
		if (props.resetValues) {
			reset({
				bankAccount: undefined,
				amount: undefined,
				withdrawalType: undefined,
			});
		}
	}, [props.resetValues, reset]);

	const handleValidSubmission = async (data: WithdrawalForm) => {
		const isValidRequest = await handleIsValidRequest();
		if (!isValidRequest) return;

		if (data?.amount && data?.bankAccount) {
			const selectedBank = backAccounts?.find(
				(item) => item.account_number === data.bankAccount,
			);

			const selectedWithdrawOption = withdrawOptions?.withdrawal_types?.find(
				(option) => option.value === DEFAULT_WITHDRAW_TYPE,
			);

			const amount = Number.parseFloat(data.amount.replace(/,/g, ""));

			const dataAsWithdrawalDetails: WithdrawalDetails = {
				accountNumber: selectedBank?.account_number,
				accountType: selectedBank?.account_type,
				amount: amount,
				bank: selectedBank?.bank,
				id: selectedBank?.id,
				withdrawalTypeFee: selectedWithdrawOption?.fee,
				withdrawalTypeName: selectedWithdrawOption?.name,
				withdrawalTypeValue: selectedWithdrawOption?.value,
			};

			props.onSubmit(dataAsWithdrawalDetails);
		}
	};

	const bankAccountInput: FormBuilderProps.FormInputProps[][] = [
		[
			{
				name: "bankAccount",
				className: "withdraw-modal-dropdown",
				options: bankAccountOptions,
				placeholder: "Select an account",
				required: true,
				filter: true,
				showLabel: false,
				title: "",
				type: "dropdown-option",
				onChange: () => {
					handleIsValidRequest();
				},
				actions: isMobile ? (
					<IconButton onClick={props.onAddBankAccount} variant="primary">
						<AddIcon width={20} height={20} />
					</IconButton>
				) : (
					<Button variant="secondary" onClick={props.onAddBankAccount}>
						<AddIcon width={20} height={20} />
						New
					</Button>
				),
				panelClassName: "withdraw-modal-dropdown-panel",
				iconColour: "#888",
				iconSize: 20,
				itemTemplate: bankAccountItemTemplate,
				valueTemplate: (
					option: DropdownOption,
					dropdownProps: DropdownProps,
				) => {
					if (option?.label && option?.value) {
						const displayOptions = option.label.split(splitter);
						return (
							<div className="withdraw-modal-bank-account-dropdown-values-selected">
								<Typography
									className="withdraw-modal-bank-account-dropdown-value"
									theme="textMd"
								>
									{displayOptions[0]}
								</Typography>
								<Typography
									className="withdraw-modal-bank-account-dropdown-value-label"
									theme="textMd"
								>
									{displayOptions[1]}
								</Typography>
							</div>
						);
					}

					return <>{dropdownProps.placeholder}</>;
				},
				dropdownRef: dropdownRef,
			},
		],
	];

	const amountInput: FormBuilderProps.FormInputProps[][] = [
		[
			{
				className: "withdraw-modal-account-number-input",
				name: "amount",
				hideAsterisk: true,
				placeholder: "0.00",
				inputMode: "decimal",
				required: true,
				showLabel: false,
				theme: "none",
				title: "Amount",
				type: "text",
				onBlur: () => setIsFocused(false),
				onFocus: () => setIsFocused(true),
				onChange: handleChangeAmount,
			},
		],
	];

	useEffect(() => {
		if (props.resetValues) {
			resetValues();
		}
	}, [props.resetValues, resetValues]);

	return (
		<Dialog
			isOpen={props.isOpen}
			onClose={props.onClose}
			size="xl"
			className="withdraw-modal"
			title="Withdraw ZAR"
			description="Withdraw ZAR via a local EFT to your South African bank account."
			fullscreen={isMobile}
			showTopbar={isMobile}
			actions={
				<>
					<Button variant="secondary" onClick={props.onClose}>
						Cancel
					</Button>
					<Button form="withdraw-form" type="submit">
						Submit
					</Button>
				</>
			}
		>
			<form
				id="withdraw-form"
				onSubmit={handleSubmit(handleValidSubmission)}
				noValidate
			>
				<div className="content-container">
					<div className="withdraw-modal-bank-account-section">
						<div className="withdraw-modal-bank-account-title-section">
							<Typography className="withdraw-modal-title" theme="textLg">
								Bank Account
							</Typography>
							<Button
								variant="tertiary"
								className={styles.manageAccountsButton}
								onClick={props.onManageAccounts}
							>
								Manage accounts
							</Button>
						</div>
						<FormBuilder
							formTitle="withdrawForm"
							formControl={control}
							errors={errors}
							formInputs={bankAccountInput}
						/>
					</div>
					<div className="withdraw-modal-withdrawal-type-title-section">
						<Typography className="withdraw-modal-title" theme="textLg">
							Withdrawal Details
						</Typography>
					</div>
					<div className="withdraw-modal-account-number-section">
						<Typography
							className={twMerge(
								"withdraw-modal-account-number-label",
								isFocused ? "text-teal-550" : "text-gray-1100",
							)}
							theme="textSm"
						>
							Amount
						</Typography>
						<div className="withdraw-modal-account-number-input-section">
							<div className="withdraw-modal-account-number-input-prefix">
								ZAR
							</div>
							<FormBuilder
								formTitle="withdrawForm"
								formControl={control}
								errors={errors}
								formInputs={amountInput}
							/>
						</div>
					</div>
					<div className={styles.capitecFeeContainer}>
						<p className={styles.capitecFeeLabel}>
							Capitec Withdrawal Fee
							<MoreInfoTooltip
								name="Capitec Withdrawal Fee"
								hasIcon
								maxWidth={280}
							>
								This processing fee is charged by Capitec. We do not charge any
								fees/commission on top of this.
							</MoreInfoTooltip>
						</p>
						{withdrawOptions?.withdrawal_types?.find(
							(current) => current.value === DEFAULT_WITHDRAW_TYPE,
						)?.fee ?? DEFAULT_FEE}
					</div>
					<Note variant="full">
						Capitec currently does not facilitate RTC/fast EFTs. Withdrawals
						typically take 3 business days to reflect.
					</Note>
				</div>
			</form>
		</Dialog>
	);
};
