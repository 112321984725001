import { useState } from "react";
import { Controller } from "react-hook-form";

import { MultiSelect } from "@app/components/controls";
import { Typography } from "@app/components/typography";

import { FieldError } from "@app/components/field-error";
import styles from "../form-builder.module.css";
import { showMappedReasons } from "../helpers";
import type { MultiSelectPartialProps } from "../types";

export const MultiSelectPartial = (props: MultiSelectPartialProps) => {
	return (
		<Controller
			name={props.name}
			control={props.formControl}
			rules={{
				required: props.required ? "This field is required" : false,
				validate: (x) =>
					props.onCustomValidationRule?.(x) || props.customErrorMessage,
			}}
			render={({ field, fieldState: { error } }) => {
				const [focused, setFocused] = useState(false);

				return (
					<div
						className={`${props.fieldRowClassName || ""} ${styles.inputRow}`}
					>
						{props.showLabel ? (
							<Typography
								theme="textSm"
								className={`${styles.formLabel} ${
									focused ? "text-teal-550" : ""
								}`}
							>
								{props.title + (props.required ? "*" : "")}
							</Typography>
						) : (
							<></>
						)}
						<MultiSelect
							disabled={props.disabled}
							error={
								!!error ||
								showMappedReasons(
									props.mappedName ?? field.name,
									props.mappedReasons,
								) !== false
							}
							filter={props.filter}
							placeholder={props.placeholder}
							options={props.options || []}
							maxSelectedLabels={props.maxSelectedLabels}
							{...field}
							optionLabel={props.optionLabel || ""}
							required={props.required}
							onChange={(value) => {
								if (props.onChange) props.onChange(value);
								field.onChange(value);
							}}
							onHide={() => setFocused(false)}
							onShow={() => setFocused(true)}
							selectedItemsScrollable
							selectedItemTemplate={props.selectedItemTemplate}
						/>
						{error && <FieldError>{error.message}</FieldError>}
						{!error &&
							showMappedReasons(
								props.mappedName ?? field.name,
								props.mappedReasons,
							)}
					</div>
				);
			}}
		/>
	);
};
