import { api } from "@app/services";
import { EntityType } from "@app/types";
import { getFormErrors } from "@app/utils/get-form-errors";
import useSWR from "swr";
import { useClients } from "./use-clients";

export type RecipientsQuery = {
	currencies: string;
	countries: string;
	ordering: string;
	recipient_name: string;
	limit: number;
	offset: number;
};

export type Recipient = {
	id: number;
	name: string;
	currencies: string;
	country: string;
	last_transaction_date: string;
	last_transaction_amount: string;
	last_transaction: string;
};

export type CreateRecipient = {
	firstName?: string;
	lastName?: string;
	legalEntityName?: string;
	entityType?: EntityType;
	currencies?: string;
	bankDetails?: {
		accountNumber?: string;
		routingNumber?: string;
		sortCode?: string;
		swiftCode?: string;
	};
	address?: {
		addressLine1?: string;
		addressLine2?: string;
		city?: string;
		province?: string;
		country?: string;
		postalCode?: string;
	};
	clientId?: number;
	isClient?: boolean;
};

export const tempRecipientMap = (recipient: Recipient) => ({
	country: recipient.country,
	currencies: recipient.currencies,
	id: recipient.id,
	lastTransactionAmount: recipient.last_transaction_amount,
	lastTransactionDate: recipient.last_transaction_date,
	name: recipient.name,
});

function mapToCreateRecipientAddressRequest(value: {
	addressLine1?: string;
	addressLine2?: string;
	city?: string;
	province?: string;
	country?: string;
	postalCode?: string;
}) {
	return {
		address_line_1: value.addressLine1,
		address_line_2: value.addressLine2,
		city: value.city,
		country: value.country,
		postal_code: value.postalCode,
		province: value.province,
	};
}

export const useRecipients = (query?: Partial<RecipientsQuery>) => {
	const { activeClientId } = useClients();

	const handleCreateRecipient = async (recipient: CreateRecipient) => {
		try {
			const result = await api.post(`clients/${activeClientId}/recipients/`, {
				address: recipient.address
					? mapToCreateRecipientAddressRequest(recipient.address)
					: {},
				bank_details: recipient.bankDetails
					? {
							account_number: recipient.bankDetails.accountNumber,
							routing_number: recipient.bankDetails.routingNumber,
							sort_code: recipient.bankDetails.sortCode,
							swift_code: recipient.bankDetails.swiftCode,
						}
					: {},
				currencies: recipient.currencies,
				entity_type: recipient.entityType,
				first_name: recipient.firstName,
				last_name: recipient.lastName,
				legal_entity_name: recipient.legalEntityName,
				is_client: recipient.isClient,
			});
			return [result.data, null];
		} catch (error) {
			return [null, getFormErrors(error)];
		}
	};

	const { data, isLoading, error } = useSWR<{
		items: Recipient[];
		count: number;
	}>(
		activeClientId && query
			? [`/clients/${activeClientId}/recipients/`, { params: query }]
			: null,
	);

	return {
		recipients: data,
		createRecipient: handleCreateRecipient,
		isLoading,
		error,
	};
};
