import { paths } from "@app/constants/paths";
import { api } from "@app/services";
import axios from "axios";

const ARB_FORGOT_PASSWORD_URL =
	"https://srv.futureforex.co.za/api/auth/users/reset_password/";

export const useAuth = () => {
	const handleForgotPassword = async (email: string) => {
		await api.post(`users/send-password-reset-link/${email}/`);
	};

	return {
		onForgotPassword: handleForgotPassword,
		onArbForgotPassword: async (email: string) => {
			await axios.post(ARB_FORGOT_PASSWORD_URL, {
				email: email.trim(),
			});
		},
		onLogout: async (params = "") => {
			await api.post("auth/logout/");
			window.sessionStorage.clear();
			window.location.href = paths().login + params;
		},
	};
};
