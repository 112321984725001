import type { DropdownChangeEvent, DropdownProps } from "primereact/dropdown";
import { type ReactNode, RefObject, useEffect, useRef, useState } from "react";

import { Dropdown as BaseDropdown } from "primereact/dropdown";
import { FiCheck, FiChevronDown, FiChevronUp } from "react-icons/fi";

import { twMerge } from "tailwind-merge";
import "./dropdown.css";
import clsx from "clsx";

const defaultVirtualizedItemHeight = 48;

export const Dropdown = <T,>(props: {
	appendToParent?: boolean;
	className?: string;
	disabled?: boolean;
	dropdownRef?: RefObject<BaseDropdown>;
	emptyMessage?: string;
	error?: boolean;
	filter?: boolean;
	filterPlaceholder?: string;
	iconColour?: string;
	iconSize?: number;
	optionGroupChildren?: string;
	optionGroupLabel?: string;
	optionLabel?: string;
	options: T[];
	panelClassName?: string;
	containerClassName?: string;
	panelFooterTemplate?:
		| ReactNode
		| ((props: DropdownProps, hide: () => void) => ReactNode);
	placeholder?: string;
	value: T | null;
	virtualized?: boolean;
	virtualizedItemHeight?: number;

	emptyFilterMessage?: (props: DropdownProps) => ReactNode;
	itemTemplate?: (option: any, value: any) => ReactNode;
	optionGroupSeparatorClassName?: (option: any, index: number) => string;
	optionGroupTemplate?: (option: any, index: number) => ReactNode;
	valueTemplate?: (option: any, props: DropdownProps) => ReactNode;
	onChange?: (value: any) => void;
	onHide?: () => void;
	onShow?: () => void;
}) => {
	const parentRef = useRef<HTMLDivElement | null>(null);

	const [selected, setSelected] = useState<T | null>(null);

	const handleChange = (event: DropdownChangeEvent) => {
		setSelected(event.value);
		if (props.onChange) props.onChange(event.value);
	};

	const itemTemplate = (option: T): ReactNode => {
		if (option === selected) {
			return (
				<div className="flex w-full flex-row items-center justify-between gap-3">
					{option as ReactNode}
					<FiCheck size={20} color="#888" />
				</div>
			);
		}

		return <>{option}</>;
	};

	useEffect(() => {
		if (props.value !== selected) {
			if (props.value === undefined) {
				setSelected(null);
			} else {
				setSelected(props.value as T);
			}
		}
	}, [props.value]);

	return (
		<div
			className={clsx("custom-dropdown", props.containerClassName)}
			ref={(parentDiv) => {
				parentRef.current = parentDiv;
			}}
		>
			<BaseDropdown
				className={twMerge(
					props.className,
					"dropdown-main",
					props.error ? "dropdown-invalid" : "",
				)}
				appendTo={props.appendToParent ? parentRef.current : undefined}
				optionLabel={props.optionLabel ?? ""}
				options={props.options}
				optionGroupChildren={props.optionGroupChildren}
				optionGroupLabel={props.optionGroupLabel}
				optionGroupTemplate={(option, index) => {
					return (
						<>
							{index > 0 && (
								<div
									className={twMerge(
										"-ml-5 -mr-3 mb-4 mt-1 border-b border-gray-110",
										props.optionGroupSeparatorClassName?.(option, index),
									)}
								/>
							)}
							{props.optionGroupTemplate && (
								<div>{props.optionGroupTemplate(option, index)}</div>
							)}
						</>
					);
				}}
				valueTemplate={props.valueTemplate}
				itemTemplate={(option: any) =>
					props.itemTemplate
						? props.itemTemplate(option, props.value)
						: itemTemplate(option)
				}
				placeholder={props.placeholder}
				panelClassName={twMerge("dropdown-panel", props.panelClassName)}
				clearIcon={false}
				disabled={props.disabled}
				emptyMessage={props.emptyMessage}
				emptyFilterMessage={props.emptyFilterMessage}
				filter={props.filter}
				filterIcon={false}
				filterPlaceholder={props.filterPlaceholder ?? "Search"}
				panelFooterTemplate={props.panelFooterTemplate}
				showClear={false}
				value={selected ?? props.value}
				virtualScrollerOptions={
					props.virtualized
						? {
								itemSize:
									props.virtualizedItemHeight ?? defaultVirtualizedItemHeight,
							}
						: undefined
				}
				dropdownIcon={(opts) => {
					return ((opts.iconProps as any)[
						"data-pr-overlay-visible"
					] as boolean) === true ? (
						<FiChevronUp
							color={props.iconColour ?? "#262626"}
							size={props.iconSize ?? 17}
							{...opts.iconProps}
						/>
					) : (
						<FiChevronDown
							color={props.iconColour ?? "#262626"}
							size={props.iconSize ?? 17}
							{...opts.iconProps}
						/>
					);
				}}
				onChange={handleChange}
				onHide={props.onHide}
				onShow={props.onShow}
				ref={props.dropdownRef}
			/>
		</div>
	);
};
