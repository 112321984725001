import { paths } from "@app/constants/paths";
import { usePayment } from "@app/features/transaction-details/payment-details/use-payment";
import { api } from "@app/services";
import { getApiError } from "@app/utils/get-api-error";
import { getFilenameFromHeader } from "@app/utils/get-filename-from-header";
import { getNavigationPathFromStatus } from "@app/utils/get-navigation-path-from-status";
import { saveAs } from "file-saver";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useClients } from "./use-clients";
import {
	CompletedTransaction,
	useCompletedTransactions,
} from "./use-completed-transactions";
import { useExchangeDetails } from "./use-exchange-details";
import {
	InProgressTransaction,
	useInProgressTransactions,
} from "./use-in-progress-transactions";
import { usePaymentStatus } from "./use-payment-status";

export const useTransaction = (transactionId?: number) => {
	const navigate = useNavigate();
	const { activeClientId } = useClients();
	const {
		data: inProgressTransactions,
		isLoading: isInProgressTransactionsLoading,
	} = useInProgressTransactions(activeClientId);
	const {
		data: completedTransactions,
		isLoading: isCompletedTransactionsLoading,
	} = useCompletedTransactions(activeClientId);
	const {
		data: exchangeDetails,
		isLoading: isExchangeDetailsLoading,
		error: exchangeDetailsError,
	} = useExchangeDetails(transactionId);

	const activePaymentId = exchangeDetails?.payment_ids[0];

	const {
		data: paymentStatus,
		isLoading: isPaymentStatusLoading,
		error: paymentStatusError,
		mutate: mutatePaymentStatus,
	} = usePaymentStatus(activePaymentId);

	const {
		data: payment,
		isLoading: isPaymentLoading,
		// error: paymentError,
		mutate: mutatePayment,
	} = usePayment(activePaymentId);

	const transaction = useMemo(() => {
		if (!inProgressTransactions || !completedTransactions) return undefined;
		const completed = completedTransactions.items.find(
			(current) => current.transaction_id === transactionId,
		);
		const inProgress = inProgressTransactions.find(
			(current) => current.transaction_id === transactionId,
		);
		return completed || inProgress;
	}, [inProgressTransactions, completedTransactions, transactionId]);

	return {
		isLoading:
			isInProgressTransactionsLoading ||
			isCompletedTransactionsLoading ||
			isExchangeDetailsLoading ||
			isPaymentStatusLoading ||
			isPaymentLoading,
		transaction: transaction as CompletedTransaction | InProgressTransaction,
		payment,
		inProgressTransactions,
		completedTransactions,
		exchangeDetails,
		paymentStatus,
		activePaymentId: exchangeDetails?.payment_ids[0],
		isExchangeDetailsLoading: isExchangeDetailsLoading,
		isPaymentStatusLoading: isPaymentStatusLoading,
		exchangeDetailsError,
		onDownloadTransaction: async () => {
			try {
				const { data, headers } = await api.get(
					`/transactions/${transactionId}/confirmation-download/`,
					{
						responseType: "blob",
					},
				);
				const fileName = getFilenameFromHeader(
					headers,
					`Transaction No.${transactionId}.pdf`,
				);
				saveAs(data, fileName);
			} catch (error) {
				return getApiError(error);
			}
		},
		onDownloadProofOfPayment: async () => {
			try {
				const { data, headers } = await api.get(
					`/payments/${activePaymentId}/proof-of-payment/`,
					{
						responseType: "blob",
					},
				);
				const fileName = getFilenameFromHeader(
					headers,
					`Proof of Payment No.${activePaymentId}.pdf`,
				);
				saveAs(data, fileName);
			} catch (error) {
				return getApiError(error);
			}
		},
		onGoToTransaction: () => {
			const isError = exchangeDetailsError || paymentStatusError;
			const isMissingDetails = !paymentStatus || !transactionId;
			if (isError || isMissingDetails) {
				navigate(
					paths().error.generalError({
						exchangeDetailsError,
						paymentStatusError,
						paymentStatus,
						transactionId,
					}),
				);
				return;
			}
			const path = getNavigationPathFromStatus(transactionId, paymentStatus);
			navigate(path);
		},
		onSubmitPayment: async (paymentId: number) =>
			api.post(`payments/${paymentId}/submit/`),
		mutatePaymentStatus,
		mutatePayment,
	};
};
