import { ApiErrors } from "@app/components/api-errors";
import { FieldError } from "@app/components/field-error";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Card } from "../card";
import { OnboardingFooter } from "../onboarding-footer";
import { OnboardingLayout } from "../onboarding-layout";

import { Dropdown } from "@app/components/dropdown";
import { Input } from "@app/components/input";
import { Label } from "@app/components/label";
import { ProcessingModal } from "@app/components/processing-modal";
import { paths } from "@app/constants/paths";
import { fetcher } from "@app/fetcher";
import { useClientProfile } from "@app/hooks/use-client-profile";
import { useFormErrors } from "@app/hooks/use-form-errors";
import { api } from "@app/services";
import { formatNumberString } from "@app/utils/format-number-string";
import { sleep } from "@app/utils/sleep";
import { useNavigate } from "react-router-dom";
import { preload } from "swr";
import { Row } from "../row";
import { TextField } from "../text-field";
import styles from "./form.module.css";
import {
	AdditionalInformation,
	useAdditionalInformation,
} from "./use-additional-information";
import {
	RegistrationReferrer,
	useRegistrationReferrers,
} from "./use-registration-referrers";

type Inputs = {
	approximate_transaction_amount: string;
	registration_referrer_id: RegistrationReferrer | undefined;
	referrer_name: string;
};

export const Form = ({ activeClientId }: { activeClientId: number }) => {
	const refName = window.localStorage.getItem("refName");
	const refId = window.localStorage.getItem("ref");

	const { data: clientProfile } = useClientProfile();
	const navigate = useNavigate();
	const [showSuccess, setShowSuccess] = useState(false);
	const { data: registrationReferrers } = useRegistrationReferrers();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isSavingChanges, setIsSavingChanges] = useState(false);
	const [showGenerallError, setShowGeneralError] = useState(false);
	const [isSaved, setIsSaved] = useState(false);
	const { submit, update } = useAdditionalInformation(activeClientId);
	const [hideReferral, setHideReferral] = useState(!!refId);

	const methods = useForm<Inputs>({
		shouldFocusError: false,
		defaultValues: async () => {
			try {
				const { data } = await api.get<AdditionalInformation>(
					`/onboarding/${activeClientId}/additional-information/`,
				);
				const registrationReferrer = await preload<RegistrationReferrer[]>(
					"onboarding/registration-referrers/",
					fetcher,
				);
				return {
					approximate_transaction_amount: data.approximate_transaction_amount
						? formatNumberString(
								data.approximate_transaction_amount.toString(10),
							)
						: "",
					registration_referrer_id: registrationReferrer.find((current) =>
						refId
							? Number.parseInt(refId, 10) === current.id
							: current.id === data.registration_referrer_id,
					),
					referrer_name: refName ?? data.referrer_name,
				} as Inputs;
			} catch {
				setShowGeneralError(true);
				return {} as Inputs;
			}
		},
	});
	const {
		register,
		handleSubmit,
		watch,
		setValue,
		setError,
		getValues,
		clearErrors,
	} = methods;
	const { errors, clearApiFieldErrors, onErrors, apiErrors, onInvalid } =
		useFormErrors(methods);

	const onSubmit: SubmitHandler<Inputs> = async (data) => {
		clearApiFieldErrors();
		if (
			!data.registration_referrer_id &&
			clientProfile?.lead_type === "inbound"
		) {
			setError("registration_referrer_id", {
				type: "required",
				message: "This field is required",
			});
			return;
		}
		setIsSubmitting(true);
		setIsSavingChanges(true);
		const startTime = Date.now();
		const errors = await submit({
			approximate_transaction_amount: Number.parseFloat(
				data.approximate_transaction_amount.replace(/,/g, ""),
			),
			registration_referrer_id: data.registration_referrer_id?.id,
			referrer_name: data.referrer_name || undefined,
		});
		if (errors) {
			onErrors(errors, true);
		} else {
			window.localStorage.removeItem("ref");
			window.localStorage.removeItem("refName");
			const pastTime = Date.now() - startTime;
			if (pastTime < 2000) {
				await sleep(2000 - pastTime);
			}
			setShowSuccess(true);
			setIsSaved(true);
			setTimeout(() => {
				window.location.href = paths().dashboard;
			}, 3500);
		}
		setIsSavingChanges(false);
		setIsSubmitting(false);
	};

	const handlePartialSave = async () => {
		const values = getValues();
		const data = {
			approximate_transaction_amount: Number.parseFloat(
				values.approximate_transaction_amount.replace(/,/g, ""),
			),
			registration_referrer_id: values.registration_referrer_id?.id,
			referrer_name: values.referrer_name,
		};
		if (Object.keys(data).length === 0) return;
		setIsSavingChanges(true);
		const errors = await update(data);
		clearApiFieldErrors();
		if (errors) {
			onErrors(errors);
		} else {
			setIsSaved(true);
		}
		setIsSavingChanges(false);
	};

	const handleNavigate = async (path: string) => {
		if (!path) return;
		navigate(path);
	};

	useEffect(() => {
		if (registrationReferrers && refId) {
			const match = registrationReferrers.find(
				(current) => current.id === Number.parseInt(refId),
			);
			if (!match || (match.requires_detail && !refName)) {
				setHideReferral(false);
				setValue("registration_referrer_id", undefined);
				setValue("referrer_name", "");
			}
		}
	}, [registrationReferrers, refId, refName, setValue]);

	return (
		<OnboardingLayout
			showError={showGenerallError}
			step={2}
			onStepNavigate={handleNavigate}
		>
			<Card>
				<form id="additional" onSubmit={handleSubmit(onSubmit, onInvalid)}>
					<Row>
						<div>
							<Label htmlFor="approximate_transaction_amount">
								Approximate transaction amount*
							</Label>
							<div className={styles.amount}>
								<div className={styles.zar}>ZAR</div>
								<Input
									className={styles.amountInput}
									placeholder="0.00"
									aria-invalid={!!errors.approximate_transaction_amount}
									{...register("approximate_transaction_amount", {
										required: "This field is required",
										validate: (value) => {
											if (value) {
												const numberValue = value.replace(/[,a-zA-Z]/g, "");
												const floatValue = Number.parseFloat(numberValue);
												if (floatValue < 0) {
													return "Amount must be greater than 0";
												}
												return true;
											}
											return true;
										},
										onBlur: handlePartialSave,
										onChange: (event) => {
											const value = event.target.value;
											const numberValue = value.replace(/[,a-zA-Z]/g, "");
											setValue("approximate_transaction_amount", numberValue);
										},
									})}
									value={formatNumberString(
										watch("approximate_transaction_amount"),
									)}
								/>
							</div>

							{errors.approximate_transaction_amount && (
								<FieldError>
									{errors.approximate_transaction_amount.message}
								</FieldError>
							)}
						</div>
					</Row>
					{clientProfile?.lead_type === "inbound" && !hideReferral && (
						<>
							<Row>
								<div>
									<Label htmlFor="registration_referrer_id">
										How did you hear about us?*
									</Label>
									<Dropdown
										onChange={(event) => {
											setValue("registration_referrer_id", event.value);
											if (event.value) {
												clearErrors("registration_referrer_id");
												if (!event.value.requires_detail)
													clearErrors("referrer_name");
												handlePartialSave();
											}
										}}
										value={watch("registration_referrer_id")}
										invalid={!!errors.registration_referrer_id}
										placeholder="Select an option"
										optionLabel="name"
										options={registrationReferrers ?? []}
									/>
									{errors.registration_referrer_id && (
										<FieldError>
											{errors.registration_referrer_id.message}
										</FieldError>
									)}
								</div>
							</Row>
							{watch("registration_referrer_id")?.requires_detail && (
								<Row>
									<TextField
										label="Referrer name*"
										placeholder="Enter full name"
										error={errors.referrer_name}
										{...register("referrer_name", {
											required: "Please enter your referrer’s full name",
											onBlur: handlePartialSave,
										})}
									/>
								</Row>
							)}
						</>
					)}
					<ApiErrors errors={apiErrors} />
				</form>
			</Card>
			<OnboardingFooter
				onBack={() => {
					handlePartialSave();
					navigate(paths().onboarding.individual.addressInformation);
				}}
				primaryText="Submit"
				isSubmitting={isSubmitting}
				formId="additional"
				isSaving={isSavingChanges}
				hasSaved={isSaved}
			/>
			<ProcessingModal
				successText="Form submitted"
				processingText="Submitting form"
				isOpen={isSubmitting || showSuccess}
				showSuccess={showSuccess}
			/>
		</OnboardingLayout>
	);
};
