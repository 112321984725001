import { twMerge } from "tailwind-merge";

import { Button } from "@app/components/button";
import { Dropdown } from "@app/components/controls";
import { CustomLoader } from "@app/components/custom-loader";
import {
	FormBuilder,
	type FormBuilderProps,
	FormBuilerHelpers,
} from "@app/components/form-builder";
import { NeedHelp } from "@app/components/need-help";
import { SignedInLayout } from "@app/components/signed-in-layout";
import { Tag } from "@app/components/tag";
import { Typography } from "@app/components/typography";
import type {
	ForexQuote,
	GetSettlementAccountSingular,
	TransactionDirection,
} from "@app/entities";
import { useMediaQuery } from "@app/hooks/use-media-query";

import "./create-quote.css";

import { CountryIcon } from "@app/components/country-icon";

import { MoreInfoTooltip } from "@app/components/more-info-tooltip";
import { Note } from "@app/components/note";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";

import { AccountManager } from "@app/hooks/use-account-manager";
import { MappedCurrency } from "@app/hooks/use-currencies";
import { ForexQuoteOptions } from "@app/hooks/use-quote-options";
import { SettlementAccount } from "@app/hooks/use-settlement-account-options";
import type { MappedReasons } from "@app/services";
import type { Control, FieldErrors } from "react-hook-form";
import { useSystemStatus } from "../../hooks/use-system-status";
import styles from "./index.module.css";
import { OutOfHoursModal } from "./out-of-hours-modal";
import { SendReceiveButtonGroup } from "./send-receive-button-group";
import { ValueDateSelect } from "./value-date-select";

export interface QuoteState {
	errors: string[];
	transactionType?: TransactionDirection;
	fxCurrency?: string;
	fxAmount?: string;
	quoteAmount?: string;
	quoteCurrency?: string;
	valueDate?: string;
	paramId?: number;
	forexQuoteData?: ForexQuote;
	rate?: string;
	settlementAccounts?: GetSettlementAccountSingular;
}

export const CreateQuoteView = (props: {
	detailsFormInputs?: FormBuilderProps.FormInputProps[][];
	errors?: FieldErrors<any>;
	formControl: Control<any, any>;
	activeClientId?: number;
	isValid: boolean;
	loading?: boolean;
	isCreatingQuote?: boolean;
	mappedReasons: MappedReasons;
	transactionType?: TransactionDirection;
	title: string;
	valueDate?: string;
	business?: boolean;
	inputFields: FormBuilderProps.FormInputProps[][];
	fxinputFields: FormBuilderProps.FormInputProps[][];
	accountManager?: AccountManager;
	currencies?: string[];
	quoteOptions?: ForexQuoteOptions;
	currenciesMapped: MappedCurrency[];
	forexQuote?: ForexQuote;
	settlementAccounts?: SettlementAccount[];
	showOutOfHoursModal?: boolean;
	fxCurrency?: string;
	onCancel: () => void;
	onSaveQuote: () => void;
	onBack: () => void;
	onSelectTransactionType: (transactionType: TransactionDirection) => void;
	onSelectValueDate: (date: string) => void;
	onOutOfHours: () => void;
	onChangeFxCurrency: (value: string) => void;
	onValidate: (type: "all" | "transaction" | "value-dates") => boolean;
	onChangeSettlementAccount: (account: number) => void;
	state: QuoteState;
}) => {
	const isMobile = useMediaQuery();
	const systemStatusResult = useSystemStatus();

	const getSelectedValueTemplate = (
		option: string,
		staticContent?: boolean,
	) => {
		const mapped = props.currenciesMapped.find(
			(x) => x.currencyCode === option,
		);
		return mapped ? (
			<span
				className={
					staticContent
						? "flex w-37.5 flex-row items-center gap-3 pl-2.5 pr-10"
						: "flex w-37.5 flex-row items-center gap-3"
				}
			>
				<CountryIcon width={24} height={24} currencyCode={option} />
				<p>{mapped.currencyCode}</p>
			</span>
		) : (
			<Typography theme="textMd" className="my-auto text-gray-300">
				Currency
			</Typography>
		);
	};

	const getSelectedValueAccountTemplate = (option?: SettlementAccount) => {
		return option ? (
			<span className="flex w-37.5 flex-row gap-2.5">
				<Typography theme="textMd" className="my-auto">
					{option.account_number} ({option.account_type})
				</Typography>
			</span>
		) : (
			<Typography theme="textMd" className="my-auto text-gray-300">
				Select a settlement account
			</Typography>
		);
	};

	const getQuoteRow = () => {
		const isSend = props.transactionType === "send";
		const paymentTypeStyle = twMerge(
			isSend
				? "border border-blue-200 bg-blue-50 flex justify-center"
				: "border border-purple-200 bg-purple-50 flex justify-center",
		);

		return (
			<>
				<div
					className={`flex items-center gap-1 rounded-10px p-3 lg:p-5 ${paymentTypeStyle}`}
				>
					{isSend ? (
						<FiArrowDown size={24} color="#262626" />
					) : (
						<FiArrowUp size={24} color="#262626" />
					)}
				</div>
				<div className="vertical-divider" />
				<div>
					<p className={styles.exchangeRate}>Exchange Rate</p>
					<p className={styles.exchangeRateValue}>
						{props.forexQuote?.quotePageDisplay
							? props.forexQuote?.quotePageDisplay
							: "-"}
					</p>
				</div>
			</>
		);
	};

	const hasDisabledDates = !!props.state.fxAmount || !!props.state.quoteAmount;

	return (
		<SignedInLayout
			title="Send/Receive funds"
			hasBackButton
			hideBottomNav
			footer={
				<div className="add-quote-footer">
					<Button onClick={() => props.onCancel()} variant="secondary">
						Cancel
					</Button>
					<Button
						disabled={props.isCreatingQuote}
						onClick={() => props.onSaveQuote()}
					>
						Continue
					</Button>
				</div>
			}
		>
			<div className="add-quote-root">
				<header className="header">
					<Typography className="title" theme="displayMd">
						Send/Receive funds
					</Typography>
				</header>

				<div className="flex w-full flex-col items-start gap-4 mobile:px-6">
					<div
						className="mt-6 lg:mt-0 flex w-full flex-row justify-start gap-2"
						data-data-pr-position="right"
						data-pr-classname="break-normal w-full"
					>
						<Typography
							className={twMerge("font-semibold", "text-gray-1100")}
							theme="textLg"
						>
							What type of transaction is this?
						</Typography>
						{props.transactionType && (
							<MoreInfoTooltip hasIcon maxWidth={400} name="Transaction type">
								<Typography theme="textSm" className="flex flex-col">
									<div className="tooltip-row">
										<strong>Send</strong> - Send foreign currency out of South
										Africa.
									</div>
									<div className="tooltip-row">
										<strong>Receive</strong> - Receive foreign currency into
										South Africa.
									</div>
								</Typography>
							</MoreInfoTooltip>
						)}
					</div>
					<SendReceiveButtonGroup
						value={props.transactionType}
						onChange={(transactionType) => {
							props.onSelectTransactionType(
								transactionType as TransactionDirection,
							);
						}}
					/>
				</div>
				{!props.transactionType && (
					<Tag
						containerClassName="flex flex-col items-start w-full gap-4 mobile:px-6 p-0"
						tagStyle="custom"
						className="bg-gray-50 px-5 py-2.5 mobile:w-full"
					>
						<div className="flex w-71 flex-col">
							<div className="tooltip-row">
								<span>Send</span> - Send foreign currency out of South Africa.
							</div>
							<div className="tooltip-row">
								<span>Receive</span> - Receive foreign currency into South
								Africa.
							</div>
						</div>
					</Tag>
				)}

				<div className="divider-wrapper">
					<div className="divider" />
				</div>

				{props.transactionType &&
					props.settlementAccounts?.length &&
					props.settlementAccounts?.length > 1 && (
						<div className="flex w-full flex-col gap-10px mobile:px-6">
							<div className="flex w-full flex-row items-start gap-2">
								<Typography
									theme="textLg"
									className="flex flex-col items-start font-semibold"
								>
									Settlement Account
								</Typography>
								<MoreInfoTooltip name="Current Account" hasIcon maxWidth={300}>
									Your account type is actually a "Trading account", but for
									purposes of depositing funds from other banks you should
									select "Current” as the account option.
								</MoreInfoTooltip>
							</div>
							<div className="flex w-full flex-col">
								<div className="accounts-dropdown flex w-full flex-row">
									<Dropdown
										options={
											props.settlementAccounts.map((x) => x.bank_account_id) ??
											[]
										}
										value={props.state.settlementAccounts?.bankAccountId}
										valueTemplate={(option) => {
											const itemtoUse = props.settlementAccounts?.find(
												(x) => x.bank_account_id === option,
											);

											return getSelectedValueAccountTemplate(itemtoUse);
										}}
										itemTemplate={(option) => {
											if (option) {
												const itemtoUse = props.settlementAccounts?.find(
													(x) => x.bank_account_id === option,
												);
												if (itemtoUse)
													return (
														<span className="flex w-37.5 flex-row gap-2.5">
															<Typography theme="textMd" className={"my-auto"}>
																{itemtoUse.account_number} (
																{itemtoUse.account_type})
															</Typography>
														</span>
													);
											}
										}}
										onChange={(event: any) => {
											props.onChangeSettlementAccount(event);
										}}
									/>
								</div>
								<div className="align-start mt-2">
									{FormBuilerHelpers.showMappedReasons(
										"settlementAccount",
										props.mappedReasons,
									)}
								</div>
							</div>
						</div>
					)}

				{props.transactionType ? (
					<div className="quote-section">
						<Typography
							theme="textLg"
							className="flex flex-col items-start font-semibold"
						>
							Transaction Amount
						</Typography>
						<div className="flex w-full flex-col gap-10px">
							<Typography theme="textSm" className={"font-semibold"}>
								{(props.transactionType || "receive") === "send"
									? "You send"
									: "You receive"}
							</Typography>
							<div className={"grid grid-cols-send-receive gap-y-1.5"}>
								<div className="selected-item-container">
									{getSelectedValueTemplate("ZAR", true)}
								</div>
								<FormBuilder
									errors={props.errors}
									formTitle={props.title}
									formInputs={props.inputFields}
									formControl={props.formControl}
								/>
								<div />
								<div className="error-field-highlight">
									{!isMobile
										? FormBuilerHelpers.showMappedReasons(
												"quoteAmount",
												props.mappedReasons,
											)
										: null}
								</div>
							</div>
							{isMobile
								? FormBuilerHelpers.showMappedReasons(
										"quoteAmount",
										props.mappedReasons,
									)
								: null}
						</div>
						<div className={styles.exchangeRow}>{getQuoteRow()}</div>
						<div className="flex w-full flex-col gap-10px">
							<Typography theme="textSm" className={"font-semibold"}>
								{(props.transactionType || "receive") === "send"
									? "They receive"
									: "They send"}
							</Typography>
							<div
								data-currency-error={!!props.mappedReasons.fxCurrency}
								className={"grid grid-cols-send-receive gap-y-1.5"}
							>
								<Dropdown
									className={`${styles.dropdownCurrency}  ${
										props.mappedReasons.fxCurrency
											? "border border-red-450"
											: ""
									}`}
									filter
									panelClassName={styles.dropdownCurrencyPanel}
									options={props.currencies ?? []}
									value={props.state.fxCurrency}
									valueTemplate={(option) => getSelectedValueTemplate(option)}
									itemTemplate={(option) => {
										const isActive = option === props.state.fxCurrency;
										const mapped = props.currenciesMapped.find(
											(x) => x.currencyCode === option,
										);
										return mapped ? (
											<div className={styles.dropdownCurrencyItem}>
												<div className={styles.dropdownCurrencyItemInner}>
													<CountryIcon
														width={24}
														height={24}
														currencyCode={mapped.currencyCode}
													/>
													<p>
														{mapped?.currencyCode} - {mapped.verboseName}
													</p>
												</div>
												{isActive && (
													<svg
														role="presentation"
														width="20"
														height="20"
														viewBox="0 0 20 20"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M16.6673 5L7.50065 14.1667L3.33398 10"
															stroke="#888888"
															strokeWidth="1.66667"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
													</svg>
												)}
											</div>
										) : undefined;
									}}
									onChange={(event: any) => {
										props.onChangeFxCurrency(event);
									}}
								/>

								<FormBuilder
									errors={props.errors}
									formTitle={props.title}
									formInputs={props.fxinputFields}
									formControl={props.formControl}
								/>

								<div />

								{!isMobile
									? FormBuilerHelpers.showMappedReasons(
											"fxAmount",
											props.mappedReasons,
										)
									: null}
							</div>
							{isMobile
								? FormBuilerHelpers.showMappedReasons(
										"fxAmount",
										props.mappedReasons,
									)
								: null}

							{FormBuilerHelpers.showMappedReasons(
								"fxCurrency",
								props.mappedReasons,
							)}
						</div>
						<Note className={styles.swiftNote} variant="full">
							SWIFT processing fees are excluded.
						</Note>
					</div>
				) : (
					<div className="flex w-full flex-row items-start gap-2 mobile:px-6">
						<Typography
							theme="textLg"
							className="flex flex-col items-start font-semibold text-gray-300"
						>
							Transaction Amount
						</Typography>
					</div>
				)}
				{!isMobile && (
					<div className="divider-wrapper">
						<div className="divider" />
					</div>
				)}

				<div className="flex w-full flex-col items-start gap-4">
					<ValueDateSelect
						transactionType={props.transactionType}
						currency={props.fxCurrency}
						disabledDates={
							hasDisabledDates
								? props.forexQuote?.valueDatesDisabled
								: undefined
						}
						variant={props.business ? "legal_entity" : "individual"}
						value={props.valueDate}
						onChange={props.onSelectValueDate}
						errors={props.mappedReasons.valueDate}
						hasError={
							props.mappedReasons.valueDate &&
							props.mappedReasons.valueDate?.length > 0
						}
					/>
				</div>

				<div className="help-section-container">
					<NeedHelp
						email={props.accountManager?.email}
						phone={props.accountManager?.contact_number}
					>
						Need help with this transaction?
					</NeedHelp>
				</div>
			</div>
			{props.loading && <CustomLoader />}
			{(!systemStatusResult.data?.transactions_enabled ||
				props.showOutOfHoursModal) && (
				<OutOfHoursModal
					isOpen
					onClose={props.onOutOfHours}
					onConfirm={props.onOutOfHours}
				/>
			)}
		</SignedInLayout>
	);
};
