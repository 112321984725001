import { Button } from "@app/components/button";
import { Carousel } from "@app/components/carousel";
import { Dropdown } from "@app/components/controls";
import { NeedHelp } from "@app/components/need-help";
import { Table } from "@app/components/table";
import { Typography } from "@app/components/typography";
import type { GetBopDetails, SuggestedPaymentBop } from "@app/entities";
import { AccountManager } from "@app/hooks/use-account-manager";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { tempPaymentStatusMapping } from "@app/hooks/use-payment-status";
import { type ReactNode, useMemo, useState } from "react";
import { twMerge } from "tailwind-merge";

import { TransactionDetailsFooter } from "../transaction-details-footer";
import { FindBopCode } from "./find-bop-code";

import styles from "./index.module.css";

import { CustomLoader } from "@app/components/custom-loader";
import { Dialog } from "@app/components/dialog";
import { Search } from "@app/components/search";
import { TransactionLayout, TransactionStep } from "../transaction-layout";
import { BopChangedModal } from "./bop-changed-modal";
import { SelectedCell } from "./selected-cell";
import { SuggestedBopCard } from "./suggested-bop-card";

interface BalanceOfPaymentTableModel {
	bop_code: ReactNode;
	category: ReactNode;
	description: ReactNode;
	id: number;
	select: ReactNode;
}

export const BalanceOfPaymentView = ({
	isSaving,
	...props
}: {
	accountManager?: AccountManager;
	currentPage?: number;
	filterString?: string;
	listPaymentBops?: GetBopDetails[];
	loading?: boolean;
	loadingTable?: boolean;
	paymentBopCategories?: string[];
	paymentStatus?: ReturnType<typeof tempPaymentStatusMapping> | null;
	popularBopSuggestions?: SuggestedPaymentBop[];
	recentBopSuggestions?: SuggestedPaymentBop[];
	paymentBopDetails?: GetBopDetails | null;
	selectedBopCategory?: string;
	isSaving?: boolean;
	selectedBopOption?: number;
	existingBopOption?: number;
	selectedRowClassName?: string;
	showBopChangedModal?: boolean;
	showUnselectedModal?: boolean;
	tableId?: string;
	tableTotal: number;
	onBack?: () => void;
	onBopChangedBack: () => void;
	onResumeLater?: () => Promise<void>;
	onBopChangedConfirm: () => void;
	onNavigateTransaction: (navigationPath: string) => void;
	onChangeCategory: (selectedBopCategory?: string) => void;
	onChangeFilterString: (
		filterString?: string,
		immediateSearch?: boolean,
	) => void;
	onChangeSelected: (id?: number) => void;
	onCloseUnselectedModal: () => void;
	onNext: () => void;
	onPageChange: (page: number, rowsPerPage: number) => void;
	onSearch?: () => void;
	onSortByName?: (directionString?: string) => void;
}) => {
	const [scrollToSuggested, setScrollToSuggested] = useState(true);
	const [shouldScrollToChange, setShouldScrollToChange] = useState(false);
	const [showResumeLater, setShowResumeLater] = useState(false);
	const [showDetail, setShowDetail] = useState(false);
	const isMobile = useMediaQuery();

	const suggestedPaymentBopOptions = useMemo(() => {
		return [
			...(props.recentBopSuggestions || []),
			...(props.popularBopSuggestions || []),
		];
	}, [props.recentBopSuggestions, props.popularBopSuggestions]);

	const renderTableRow = (
		current: GetBopDetails,
	): BalanceOfPaymentTableModel => {
		return {
			bop_code: current.bopCode,
			category: current.category,
			description: current.description,
			select: (
				<SelectedCell
					shouldScrollToChange={shouldScrollToChange}
					isSelected={
						!!props.selectedBopOption && current.id === props.selectedBopOption
					}
					id={current.id}
					onChangeSelected={(id) => {
						setShouldScrollToChange(false);
						props.onChangeSelected(id);
					}}
				/>
			),
			id: current.id,
		};
	};

	const currentBop = props.listPaymentBops?.find(
		(current) => current.id === props.selectedBopOption,
	);

	return (
		<TransactionLayout
			step={TransactionStep.BalanceOfPayment}
			onStepNavigate={props.onNavigateTransaction}
			onResumeLater={() => setShowResumeLater(true)}
			footer={
				<TransactionDetailsFooter
					hasAutoSave
					isSaving={isSaving}
					showResumeLater={showResumeLater}
					onResumeLaterChange={setShowResumeLater}
					onResumeLater={props.onResumeLater}
					onBack={() => {
						if (props.onBack) props.onBack();
					}}
					onNext={props.onNext}
				/>
			}
		>
			<div className={styles.container}>
				<h2 className={styles.title}>BoP Details</h2>
				<p className={styles.description}>
					All cross-border transactions need to be reported to the South African
					Reserve Bank (SARB).{" "}
					{!isMobile || showDetail
						? "This reporting relates to the reason for the transaction, which is captured using Balance of Payment (BoP) codes."
						: null}
					{isMobile && (
						<button
							className={styles.readMoreButton}
							type="button"
							onClick={() => setShowDetail(!showDetail)}
						>
							{!showDetail ? "Read more" : "Read less"}
						</button>
					)}
				</p>

				<Carousel
					displayAmount
					heading="Suggested"
					className="mb-6"
					values={suggestedPaymentBopOptions}
					template={(value) => {
						const isPopular =
							props.popularBopSuggestions?.find((x) => x.id === value.id) !==
							undefined;
						const isRecent =
							props.recentBopSuggestions?.find((x) => x.id === value.id) !==
							undefined;
						return (
							<SuggestedBopCard
								shouldScroll={scrollToSuggested}
								onScrollComplete={() => {
									setScrollToSuggested(false);
								}}
								value={value}
								isSelected={
									!!props.selectedBopOption &&
									value.id === props.selectedBopOption
								}
								isPopular={isPopular}
								isRecent={isRecent}
								onChange={(option) => {
									setShouldScrollToChange(true);
									props.onChangeSelected(option.id);
								}}
							/>
						);
					}}
				/>

				{isMobile && (
					<FindBopCode
						allowDeselect={false}
						current={currentBop}
						onChangeSelected={(id) => {
							props.onChangeSelected(id);
						}}
						data={props.listPaymentBops}
						selected={props.selectedBopOption}
						category={props.selectedBopCategory}
						onChangeCategory={props.onChangeCategory}
						categoryOptions={props.paymentBopCategories}
						search={props.filterString}
						onSearchChange={props.onChangeFilterString}
					/>
				)}

				<NeedHelp
					email={props.accountManager?.email}
					phone={props.accountManager?.contact_number}
				>
					Need help finding the right BoP code?
				</NeedHelp>

				{!isMobile && (
					<>
						<div className={styles.divider} />
						<Typography
							theme="displayXs"
							className="mt-6 font-display leading-11 text-gray-1100"
						>
							Find BoP code
						</Typography>
						<div className="mt-6">
							<div className="flex flex-row items-end gap-x-5">
								<div>
									<Typography theme="textSm" className="mb-1.5 font-semibold">
										Filter by category
									</Typography>
									<Dropdown
										className="w-82"
										filter
										options={props.paymentBopCategories ?? []}
										panelClassName="form-dropdown filterable"
										placeholder="Select category"
										value={props.selectedBopCategory}
										onChange={props.onChangeCategory}
									/>
								</div>
								<div className="w-full">
									<Typography theme="textSm" className="mb-1.5 font-semibold">
										Search
									</Typography>
									<div className="relative">
										<Search
											placeholder="Enter a BoP code or search term"
											value={props.filterString}
											onChange={(event) =>
												props.onChangeFilterString(event.target.value)
											}
											onKeyDown={(event) => {
												if (event.key === "Enter") {
													props.onChangeFilterString(props.filterString, true);
												}
											}}
											onClear={() => props.onChangeFilterString("", true)}
										/>
									</div>
								</div>
								<Button
									className="w-full min-w-25 max-w-25 py-3"
									onClick={props.onSearch}
								>
									Search
								</Button>
							</div>
						</div>

						<div className={styles.tableContainer}>
							<Table
								id={props.tableId}
								lazy
								loading={props.loadingTable}
								scrollable
								scrollHeight="560px"
								sortFunction={(event) => {
									let directionString = "";
									if (event.sortOrder === -1) directionString = "-";

									directionString += event.sortField;

									if (props.onSortByName) props.onSortByName(directionString);

									return event;
								}}
								columns={[
									{
										field: "bop_code",
										header: "Code",
										sortable: true,
										width: "12.5%",
									},
									{
										field: "category",
										header: "Category",
										sortable: true,
										width: "30%",
									},
									{
										field: "description",
										header: "Description",
										sortable: false,
										width: "42.5%",
									},
									{
										field: "select",
										header: "",
										width: "15%",
									},
								]}
								rowClassName={(data) => {
									const bopData = data as GetBopDetails;
									return bopData.id === props.selectedBopOption
										? twMerge("bg-teal-25", props.selectedRowClassName)
										: "";
								}}
								stripedRows
								values={props.listPaymentBops?.map(renderTableRow)}
								totalRecords={props.tableTotal || 0}
							/>
						</div>
					</>
				)}
			</div>

			{props.showBopChangedModal && (
				<BopChangedModal
					onBack={props.onBopChangedBack}
					onConfirm={props.onBopChangedConfirm}
				/>
			)}
			{props.showUnselectedModal && (
				<Dialog
					isOpen
					onClose={props.onCloseUnselectedModal}
					title="BoP code required"
					description="Please select a BoP code to continue"
					actions={
						<Button centered onClick={props.onCloseUnselectedModal}>
							Back
						</Button>
					}
				/>
			)}
			{props.loading && <CustomLoader />}
		</TransactionLayout>
	);
};
