import clsx from "clsx";
import { useState } from "react";
import { Button } from "../button";
import { Dialog } from "../dialog";
import { Search } from "../search";

import styles from "./index.module.css";

export const MobileSelectBottomsheet = ({
	options,
	searchPlaceholder = "Search",
	hasError = false,
	className,
	disabled = false,
	onChange,
}: {
	options: Array<string>;
	searchPlaceholder?: string;
	hasError?: boolean;
	className?: string;
	disabled?: boolean;
	onChange?: (value: string) => void;
}) => {
	const [show, setShow] = useState(false);
	const [filterText, setFilterText] = useState("");
	return (
		<>
			<button
				data-error={hasError}
				disabled={disabled}
				className={clsx(className)}
				type="button"
				onClick={() => setShow(true)}
			>
				Trigger
			</button>
			<Dialog
				bottomsheet
				backdropClassName={styles.backdrop}
				wrapperClassName={styles.wrapper}
				onClose={() => setShow(false)}
				isOpen={show}
				title="Need help?"
				actions={<Button onClick={() => setShow(false)}>Cancel</Button>}
			>
				{!!searchPlaceholder && (
					<Search
						value={filterText}
						onChange={(event) => setFilterText(event.target.value)}
						placeholder={searchPlaceholder}
					/>
				)}
			</Dialog>
		</>
	);
};
