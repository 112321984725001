import { useClients } from "@app/hooks/use-clients";
import { api } from "@app/services";
import { getFormErrors } from "@app/utils/get-form-errors";
import useSWR from "swr";

export const useWithdrawal = () => {
	const { activeClientId } = useClients();
	const {
		data: backAccounts,
		isLoading: isLoadingBackAccounts,
		mutate: mutateBackAccounts,
	} = useSWR<
		Array<{
			id: number;
			account_holder: string | null;
			bank: string;
			account_number: string;
			account_type: string;
		}>
	>(
		activeClientId
			? `clients/${activeClientId}/withdrawal-bank-accounts/`
			: null,
	);

	const { data: withdrawOptions, isLoading: isLoadingWithdrawOptions } =
		useSWR<{
			withdrawal_types: Array<{
				name: string;
				value: string;
				fee: string;
			}>;
		}>("withdrawal-bank-accounts/options/");

	const validateRequest = async (
		payload: Partial<{
			client_id: number;
			bank_id: number;
			amount: number;
			withdrawal_type: string;
		}>,
	) => {
		try {
			await api.patch("withdrawals/request/", payload);
		} catch (error) {
			return getFormErrors(error);
		}
	};

	return {
		backAccounts,
		withdrawOptions,
		validateRequest,
		isLoadingBackAccounts,
		mutateBackAccounts,
		isLoading: isLoadingBackAccounts || isLoadingWithdrawOptions,
	};
};
