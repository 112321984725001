import { FiBriefcase, FiUser } from "react-icons/fi";

import { Title } from "@app/components/title";

import { paths } from "@app/constants/paths";
import { EntityType } from "@app/types";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ButtonGroup } from "../../button-group";
import { isReferralEnabled } from "@app/constants/feature-flags";

export const RegisterOptions = ({
	onRegisterAs,
}: {
	onRegisterAs: (type: EntityType) => void;
}) => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const handleSetRefInformation = () => {
		if (isReferralEnabled) {
			const ref = searchParams.get("ref");
			const refName = searchParams.get("refName");
			if (ref) {
				window.localStorage.setItem("ref", ref);
			}
			if (refName) {
				window.localStorage.setItem("refName", refName);
			}
		}
	};

	return (
		<div>
			<Title>Register as</Title>
			<ButtonGroup
				data={[
					{
						icon: <FiUser size={32} color={"#56A7A2"} />,
						title: "An individual",
						onClick: () => {
							handleSetRefInformation();
							navigate(paths().selectService);
						},
					},
					{
						icon: <FiBriefcase size={32} color={"#56A7A2"} />,
						title: "A business",
						onClick: () => {
							handleSetRefInformation();
							onRegisterAs("legal_entity");
						},
					},
				]}
			/>
		</div>
	);
};
