import { type LoginFailureResponse, api } from "@app/services";
import axios from "axios";

const ARB_LOGIN_URL = "https://srv.futureforex.co.za/api/auth/token/login/";
const ARB_STORAGE_KEY = "arb-token";

const getLoginErrors = (error: any) => {
	try {
		const data = (error?.data || error?.response?.data) as LoginFailureResponse;
		if (!data) return ["An unexpected error occurred. Please try again."];
		if (error.response?.status === 403) {
			return ["Too many failed login attempts. please contact us."];
		}

		const errors: Array<string> = [];
		if (data.detail && typeof data.detail === "string") {
			errors.push(data.detail);
		} else if (data.message && typeof data.message === "string") {
			errors.push(data.message);
		}
		if (data.non_field_errors) errors.push(...data.non_field_errors);
		if (data.username)
			errors.push(...data.username.map((x) => `username: ${x}`));
		if (data.password)
			errors.push(...data.password.map((x) => `password: ${x}`));
		return errors;
	} catch {
		return ["An unexpected error occurred. Please try again."];
	}
};

type LoginForm = {
	username: string;
	password: string;
};

export const useLogin = () => {
	const handleForexLogin = async ({ username, password }: LoginForm) => {
		try {
			await api.post("auth/login/", {
				username: `${username}`.trim(),
				password: `${password}`.trim(),
			});
			return [];
		} catch (error) {
			return getLoginErrors(error);
		}
	};

	const handleArbLogin = async ({ username, password }: LoginForm) => {
		try {
			const result = await axios.post<{ auth_token: string }>(ARB_LOGIN_URL, {
				username: `${username}`.trim(),
				password: `${password}`.trim(),
			});
			window.localStorage.setItem(ARB_STORAGE_KEY, result.data.auth_token);
			return [];
		} catch (error) {
			return getLoginErrors(error);
		}
	};

	const handleGoToArb = (isInactiveUser?: boolean) => {
		const baseUrl = window.location.href.includes("test")
			? "https://test-dashboard.futureforex.co.za/"
			: "https://dashboard.futureforex.co.za/";

		const token = window.localStorage.getItem(ARB_STORAGE_KEY);
		let redirectUrl = `${baseUrl}?login-source=OFP`;
		if (token) {
			redirectUrl += `&auth-token=${token}`;
		}
		if (isInactiveUser) {
			redirectUrl += "&inactive=true";
		}
		window.location.href = redirectUrl;
	};

	return {
		onForexLogin: handleForexLogin,
		onArbLogin: handleArbLogin,
		onGoToArb: handleGoToArb,
	};
};
