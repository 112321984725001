import type { DropdownProps } from "primereact/dropdown";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import { type FormBuilderProps } from "@app/components/form-builder";
import { Typography } from "@app/components/typography";
import type {
	AccountType,
	AddEditBankAccountForm,
	DropdownOption,
	LocalBank,
	UpdateWithdrawalBankAccount,
} from "@app/entities";
import {
	useCreateWithdrawalBankAccount,
	useGetWithdrawalBankAccounts,
	useShowToast,
	useUpdateWithdrawalBankAccount,
} from "@app/helpers";
import type { RootState } from "@app/redux";

import { useClients } from "@app/hooks/use-clients";
import { MappedReasons } from "@app/services";
import { useWithdrawalBankAccountOptions } from "../use-withdrawal-bank-account-options";
import { AddEditBankAccountModalView } from "./add-edit-bank-account-modal-view";
import type { ViewProperties } from "./properties";

import { useClientProfile } from "@app/hooks/use-client-profile";
import { MappedCurrency } from "@app/hooks/use-currencies";
import { useMediaQuery } from "@app/hooks/use-media-query";
import styles from "./index.module.css";

export interface LogicState {
	mappedReasons?: MappedReasons;
}

export const AddEditBankAccountModal = (props: {
	isEdit?: boolean;
	isOpen: boolean;
	onBack: () => void;
	onClose: () => void;
	onSave: () => void;
}) => {
	const isMobile = useMediaQuery();
	const {
		control,
		handleSubmit,
		formState: { errors },
		reset,
		getValues,
	} = useForm<AddEditBankAccountForm>({
		defaultValues: {},
		mode: "onChange",
	});
	const [updateWithdrawalBankAccount] = useUpdateWithdrawalBankAccount();
	const [createWithdrawalBankAccount] = useCreateWithdrawalBankAccount();
	const [getWithdrawalBankAccounts] = useGetWithdrawalBankAccounts();
	const [showToast] = useShowToast();

	const { activeClient } = useClients();
	const { data: clientProfile } = useClientProfile();
	const withdrawalBankAccountOptionsResult = useWithdrawalBankAccountOptions();

	const { withdrawalBankAccount } = useSelector(
		(rootState: RootState) => rootState.withdrawals,
	);

	const [logicState, setLogicState] = useState<LogicState>();

	const getUserDetails = useCallback(() => {
		const values = getValues();

		let resetValues = {
			...values,
			accountNumber:
				props.isEdit && withdrawalBankAccount
					? withdrawalBankAccount.accountNumber
					: "",
			accountType:
				props.isEdit && withdrawalBankAccount
					? withdrawalBankAccount.accountType
					: "",
			bankAccount:
				props.isEdit && withdrawalBankAccount ? withdrawalBankAccount.bank : "",
		};

		if (clientProfile?.entity_type === "individual") {
			resetValues = {
				...resetValues,
				firstName: clientProfile.first_name,
				lastName: clientProfile.last_name,
			};
		} else if (clientProfile?.entity_type === "legal_entity" && activeClient) {
			resetValues = {
				...resetValues,
				name: activeClient.name,
			};
		}

		reset(resetValues);
	}, [
		clientProfile,
		activeClient,
		getValues,
		props.isEdit,
		withdrawalBankAccount,
		reset,
	]);

	const getBankAccounts = (): string[] => {
		if (withdrawalBankAccountOptionsResult.data?.local_banks) {
			return withdrawalBankAccountOptionsResult.data?.local_banks
				.map((x: LocalBank) => {
					return x.name;
				})
				.filter((x): x is string => x !== undefined);
		}
		return [];
	};

	const getBankAccountTypes = () => {
		if (withdrawalBankAccountOptionsResult.data?.account_types) {
			return withdrawalBankAccountOptionsResult.data.account_types.map<DropdownOption>(
				(accountType: AccountType) => {
					return {
						label: accountType.name || "",
						value: accountType.value || "",
					};
				},
			);
		}
		return [];
	};

	const accountTypesValueTemplate = (
		option: DropdownOption,
		dropdownProps: DropdownProps | MappedCurrency,
	) => {
		if (option?.label) {
			return <Typography theme="textMd">{option.label}</Typography>;
		}

		return <>{(dropdownProps as DropdownProps).placeholder}</>;
	};

	const onHandleSubmitValid = (data: AddEditBankAccountForm) => {
		if (data.accountNumber && data.accountType && data.bankAccount) {
			const selectedBank =
				withdrawalBankAccountOptionsResult.data?.local_banks?.find(
					(item) => item.name === data.bankAccount,
				);

			const dataAsAddEditDetails: UpdateWithdrawalBankAccount = {
				accountNumber: data.accountNumber,
				accountType: data.accountType,
				bankId: selectedBank?.id,
				bankAccountId: withdrawalBankAccount?.id,
			};

			if (props.isEdit) {
				updateWithdrawalBankAccount(
					dataAsAddEditDetails,
					(errors, mappedReasons) => {
						if (!errors || (errors.length === 0 && !mappedReasons)) {
							showToast("Account edited", "success");
							getWithdrawalBankAccounts();
							setLogicState({
								...logicState,
								mappedReasons: undefined,
							});
							props.onSave();
						} else {
							setLogicState({
								...logicState,
								mappedReasons,
							});
						}
					},
				);
			} else {
				createWithdrawalBankAccount(
					dataAsAddEditDetails,
					(errors, mappedReasons) => {
						if (!errors || (errors.length === 0 && !mappedReasons)) {
							showToast("Account added", "success");
							getWithdrawalBankAccounts();
							setLogicState({
								...logicState,
								mappedReasons: undefined,
							});
							props.onSave();
						} else {
							setLogicState({
								...logicState,
								mappedReasons,
							});
						}
					},
				);
			}
		}
	};

	let nameFields: Array<FormBuilderProps.FormInputProps>;

	const firstNameField: FormBuilderProps.FormInputProps = {
		name: "firstName",
		placeholder: "",
		showLabel: true,
		title: "First Name",
		type: "text",
		hideAsterisk: true,
		onChange: () => {},
		className: styles.disabled,
		disabled: true,
	};

	const lastNameField: FormBuilderProps.FormInputProps = {
		name: "lastName",
		placeholder: "",
		showLabel: true,
		title: "Last Name",
		type: "text",
		hideAsterisk: true,
		onChange: () => {},
		className: styles.disabled,
		disabled: true,
	};

	const nameField: FormBuilderProps.FormInputProps = {
		name: "name",
		placeholder: "",
		showLabel: true,
		title: "Name",
		type: "text",
		hideAsterisk: true,
		onChange: () => {},
		className: styles.disabled,
		disabled: true,
	};
	const fields =
		clientProfile?.entity_type === "individual"
			? isMobile
				? [[firstNameField], [lastNameField]]
				: [[firstNameField, lastNameField]]
			: [[nameField]];

	const bankAccountInputs: FormBuilderProps.FormInputProps[][] = [
		...fields,
		[
			{
				name: "bankAccount",
				className: "add-edit-bank-account-dropdown",
				options: getBankAccounts(),
				placeholder: "Select bank",
				required: true,
				filter: true,
				showLabel: true,
				title: "Bank",
				hideAsterisk: true,
				type: "dropdown",
				onChange: () => {},
				panelClassName: "add-edit-bank-account-dropdown-panel",
				iconColour: "#888",
				iconSize: 20,
			},
		],
		[
			{
				name: "accountNumber",
				placeholder: "Enter account number",
				required: true,
				showLabel: true,
				title: "Account number",
				type: "text",
				hideAsterisk: true,
				onChange: () => {},
				className: styles.input,
			},
		],
		[
			{
				name: "accountType",
				options: getBankAccountTypes(),
				valueTemplate: accountTypesValueTemplate,
				itemTemplate: accountTypesValueTemplate,
				className: "add-edit-bank-account-dropdown",
				placeholder: "Select account type",
				required: true,
				filter: true,
				showLabel: true,
				title: "Account Type",
				hideAsterisk: true,
				type: "dropdown-option",
				onChange: () => {},
				panelClassName: "add-edit-bank-account-dropdown-panel",
				iconColour: "#888",
				iconSize: 20,
			},
		],
	];

	useEffect(() => {
		if (props.isOpen) {
			getUserDetails();
		}
	}, [props.isOpen, getUserDetails]);

	const viewProps: ViewProperties = {
		...props,
		errors,
		formControl: control,
		formInputs: bankAccountInputs,
		mappedReasons: logicState?.mappedReasons,
		handleSubmit,
		onHandleSubmitValid,
	};
	return <AddEditBankAccountModalView {...viewProps} />;
};
