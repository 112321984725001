const PROD_HOST_NAME = "account.futureforex.co.za";
const DEMO_HOST_NAME = "demo-account.futureforex.co.za";
const TEST_HOST_NAME = "test-account.futureforex.co.za";
const TEST_2_HOST_NAME = "test2-account.futureforex.co.za";

export const isLocalhost = window.location.hostname === "localhost";
export const isProd = window.location.hostname === PROD_HOST_NAME;
export const isDemo = window.location.hostname === DEMO_HOST_NAME;
export const isTest = window.location.hostname === TEST_HOST_NAME;
export const isTest2 = window.location.hostname === TEST_2_HOST_NAME;
export const isDevServer = import.meta.env.DEV;
export const isPWA = !!(window.navigator as Navigator).standalone;

export const apiUrl = isProd
	? "https://main.futureforex.co.za/"
	: isTest2
		? "https://test2-main.futureforex.co.za/"
		: isDemo
			? "https://demo-main.futureforex.co.za/"
			: "https://test-main.futureforex.co.za/";
export const wsUrl = isProd
	? "wss://main.futureforex.co.za/"
	: isTest2
		? "wss://test2-main.futureforex.co.za/"
		: isDemo
			? "wss://demo-main.futureforex.co.za/"
			: "wss://test-main.futureforex.co.za/";
