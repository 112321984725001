import { Controller } from "react-hook-form";
import { twMerge } from "tailwind-merge";

import { Typography } from "@app/components/typography";

import styles from "../form-builder.module.css";
import { showMappedReasons } from "../helpers";
import type { InputFieldPartialProps } from "../types";

import { FieldError } from "@app/components/field-error";
import inputStyles from "./input-field-partial.module.css";

export const InputFieldPartial = (props: InputFieldPartialProps) => {
	return (
		<Controller
			name={props.name}
			control={props.formControl}
			rules={{
				required: props.required ? "This field is required" : false,
				minLength: props.minLength,
				maxLength: props.maxLength,
				pattern: props.pattern,
				validate: (x) =>
					props.onCustomValidationRule?.(x) || props.customErrorMessage,
			}}
			render={({ field, fieldState: { error } }) => {
				const mappedReason = showMappedReasons(
					props.mappedName ?? field.name,
					props.mappedReasons,
				);
				const hasError = error || mappedReason;

				return (
					<div
						className={`${props.fieldRowClassName || ""} ${styles.inputRow}`}
					>
						{props.showLabel ? (
							<Typography
								theme="textSm"
								className={`${styles.formLabel} text-input-label`}
							>
								{props.title +
									(props.required && !props.hideAsterisk ? "*" : "")}
							</Typography>
						) : (
							<></>
						)}
						<input
							className={twMerge(
								inputStyles.input,
								props.className,
								styles.formBuilderInput,
								hasError && inputStyles.error,
								props.disabled ? styles.inputDisabled : "",
							)}
							required={props.required || false}
							type={props.type}
							inputMode={props.inputMode}
							{...field}
							placeholder={props.placeholder}
							onChange={(event) => {
								const value = event.target.value;
								field.onChange(value);
								if (props.onChange) props.onChange(value);
							}}
							onBlur={props.onBlur}
							onFocus={props.onFocus}
							disabled={props.disabled}
						/>

						{error ? <FieldError>{error.message}</FieldError> : mappedReason}
					</div>
				);
			}}
		/>
	);
};
