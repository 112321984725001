import { NeedHelp } from "@app/components/need-help";
import { SignedInLayout } from "@app/components/signed-in-layout";
import { paths } from "@app/constants/paths";
import { useAccountManager } from "@app/hooks/use-account-manager";
import { useClients } from "@app/hooks/use-clients";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { useOnboardingStatus } from "@app/hooks/use-onboarding-status";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useSWR from "swr";
import { LogoutResumeLaterModal } from "../logout-resume-later-modal";
import { OnboardingSteps } from "../onboarding-steps";
import {
	LinkableRelatedEntities,
	RelatedEntities,
} from "../related-entities/use-related-entities";
import { Title } from "../title";
import { TutorialStep } from "../tutorial-step";
import { useTutorial } from "../tutorial-step/use-tutorial";
import styles from "./index.module.css";
import { OnboardingMobileHeader } from "./onboarding-mobile-header";

import { NavigationStep } from "@app/components/navigation-steps";
import { GeneralError } from "@app/features/errors/general-error";
import { useClientProfile } from "@app/hooks/use-client-profile";
import { EntityType } from "@app/types";
import substepCheckSrc from "./substep-check.svg";
import substepDirectorSrc from "./substep-director.svg";
import substepShareholderSrc from "./substep-shareholder.svg";
import substepSignatorySrc from "./substep-signatory.svg";

const getSubstepsForVariant = (
	relatedEntities: RelatedEntities | undefined,
	variant: keyof RelatedEntities,
	activeClientId?: number,
	tempEntities?: Array<number>,
) => {
	if (!relatedEntities || !activeClientId) return;

	let currentTempEntities: Array<number> = tempEntities ?? [];
	if (!tempEntities) {
		try {
			currentTempEntities = JSON.parse(
				window.localStorage.getItem(`${activeClientId}-${variant}-entities`) ??
					"",
			);
		} catch {}
	}

	const matchingEntities = relatedEntities[variant] ?? [];
	const defaultName =
		variant === "director"
			? "Director"
			: variant === "authorised_signatory"
				? "Signatory"
				: "Shareholder";
	const isEditableSupstepSrc =
		variant === "director"
			? substepDirectorSrc
			: variant === "authorised_signatory"
				? substepSignatorySrc
				: substepShareholderSrc;
	const result = [
		...(matchingEntities as Array<LinkableRelatedEntities>).map(
			(current, index) => ({
				name: current.name.trim()
					? current.name
					: `${defaultName} ${index + 1}`,
				iconSrc: current.complete ? substepCheckSrc : isEditableSupstepSrc,
				id: current.related_entity_id.toString(),
				linkId: current.id.toString(),
			}),
		),
		...currentTempEntities.map((_, index) => ({
			name: `${defaultName} ${(matchingEntities as Array<LinkableRelatedEntities>).length + index + 1}`,
			iconSrc: isEditableSupstepSrc,
			id: index.toString(),
		})),
	];

	if (result.length === 0) return;

	return result;
};

export const OnboardingLayout = ({
	children,
	step = 0,
	variant = "individual",
	noWrapper = false,
	onStepNavigate,
	showError = false,
	tempEntities,
}: {
	children: ReactNode;
	step?: number;
	variant?: EntityType;
	noWrapper?: boolean;
	showError?: boolean;
	onStepNavigate?: (path: string, targetId?: string | undefined) => void;
	tempEntities?: Array<number>;
}) => {
	const navigate = useNavigate();

	const { disable, isEnabled } = useTutorial();
	const [showResumeLaterModal, setShowResumeLaterModal] = useState(false);
	const location = useLocation();

	const { activeClientId } = useClients();
	const { data: clientProfile } = useClientProfile();
	const { formProgress, error } = useOnboardingStatus(activeClientId);
	const { data: accountManager } = useAccountManager();
	const { data: relatedEntities } = useSWR<RelatedEntities>(
		variant === "legal_entity" && activeClientId
			? `/onboarding/${activeClientId}/related-entities/`
			: null,
	);

	const isPrimaryShareholder = location.pathname.includes(
		"primary-shareholders",
	);

	useEffect(() => {
		if (isPrimaryShareholder) return;

		const shouldRedirectToIndividual =
			clientProfile?.entity_type === "individual" && variant === "legal_entity";
		const shouldRedirectToBusiness =
			clientProfile?.entity_type === "legal_entity" && variant === "individual";

		if (shouldRedirectToIndividual) {
			navigate(paths().onboarding.individual.personalInformation);
		}
		if (shouldRedirectToBusiness) {
			navigate(paths().onboarding.business.companyBasics);
		}
	}, [clientProfile?.entity_type, navigate, variant, isPrimaryShareholder]);

	useEffect(() => {
		if (isPrimaryShareholder) return;
		if (error && error.status === 403) {
			navigate(paths().dashboard);
		}
	}, [error, navigate, isPrimaryShareholder]);

	const isMobile = useMediaQuery();

	const steps: Array<NavigationStep> = useMemo(() => {
		const completedSteps =
			formProgress?.awaiting_submission_details.steps_completed ?? [];
		if (isPrimaryShareholder) {
			return (
				relatedEntities?.shareholder?.map((shareholder) => {
					return {
						title: shareholder.name,
						completed: shareholder.complete,
						description: undefined,
						path: shareholder.related_entity_id.toString(),
						key: undefined,
						active: false,
					};
				}) ?? []
			);
		}

		if (variant === "individual") {
			return [
				{
					title: "Identity",
					key: "personal_details",
					completed: completedSteps.includes("personal_details"),
					disabled: false,
					active:
						location.pathname ===
							paths().onboarding.individual.personalInformation || step === 0,
					path: paths().onboarding.individual.personalInformation,
				},
				{
					title: "Address",
					key: "address",
					completed: completedSteps.includes("address"),
					disabled: !completedSteps.includes("personal_details"),
					active:
						location.pathname ===
							paths().onboarding.individual.addressInformation || step === 1,
					path: paths().onboarding.individual.addressInformation,
				},
				{
					title: "Additional details",
					key: "additional_details",
					disabled: !completedSteps.includes("address"),
					completed: completedSteps.includes("additional_details"),
					active:
						location.pathname ===
							paths().onboarding.individual.additionalDetails || step === 2,
					path: paths().onboarding.individual.additionalDetails,
				},
			];
		}

		const isDirectors =
			location.pathname === paths().onboarding.business.directors || step === 2;
		const isSignatories =
			location.pathname === paths().onboarding.business.signatories ||
			step === 3;
		const isShareholders =
			location.pathname === paths().onboarding.business.shareholders ||
			step === 4;

		return [
			{
				title: "Company basics",
				key: "basic_details",
				completed: completedSteps.includes("basic_details"),
				active:
					location.pathname === paths().onboarding.business.companyBasics ||
					step === 0,
				path: paths().onboarding.business.companyBasics,
			},
			{
				title: "Company details",
				key: "company_details",
				completed: completedSteps.includes("company_details"),
				active:
					location.pathname === paths().onboarding.business.companyDetails ||
					step === 1,
				path: paths().onboarding.business.companyDetails,
			},
			{
				title: "Directors",
				key: "directors_details",
				completed: completedSteps.includes("directors_details"),
				active: isDirectors,
				path: paths().onboarding.business.directors,
				subSteps: getSubstepsForVariant(
					relatedEntities,
					"director",
					activeClientId,
					isDirectors ? tempEntities : undefined,
				),
			},
			{
				title: "Signatories",
				description:
					"Add details for persons who have authority to act on the company’s account.",
				key: "signatories_details",
				completed: completedSteps.includes("signatories_details"),
				active: isSignatories,
				path: paths().onboarding.business.signatories,
				subSteps: getSubstepsForVariant(
					relatedEntities,
					"authorised_signatory",
					activeClientId,
					isSignatories ? tempEntities : undefined,
				),
			},
			{
				title: "Shareholders",
				key: "shareholders_details",
				completed: completedSteps.includes("shareholders_details"),
				active: isShareholders,
				path: paths().onboarding.business.shareholders,
				subSteps: getSubstepsForVariant(
					relatedEntities,
					"shareholder",
					activeClientId,
					isShareholders ? tempEntities : undefined,
				),
			},
		];
	}, [
		variant,
		location,
		step,
		formProgress,
		relatedEntities,
		activeClientId,
		isPrimaryShareholder,
		tempEntities,
	]);

	useOnboardingStatus(activeClientId, steps[step]?.key);

	const active = steps[step];
	const showTutorial =
		step === 0 && !isMobile && !isPrimaryShareholder && isEnabled;

	return (
		<SignedInLayout
			variant="onboarding"
			overscrollDisabled
			hideBottomNav
			mobileHeader={
				<OnboardingMobileHeader
					variant={variant}
					step={step}
					steps={steps}
					onLogout={() => setShowResumeLaterModal(true)}
					onStepNavigate={onStepNavigate}
				/>
			}
			content={
				<OnboardingSteps
					onStepNavigate={onStepNavigate}
					type={variant === "legal_entity" ? "Business" : "Individual"}
					steps={steps}
					variant={variant}
				/>
			}
		>
			{showError ? (
				<GeneralError showHeader={false} />
			) : noWrapper ? (
				children
			) : (
				<main className={styles.content}>
					{!isMobile && (
						<Title>
							{isPrimaryShareholder ? "Primary Shareholders" : active.title}
						</Title>
					)}
					{isPrimaryShareholder ? (
						<p className={styles.description}>
							Provide information for the following primary shareholders/UBOs
							(persons with +5% effective ownership).
						</p>
					) : active.description ? (
						<p className={styles.description}>{active.description}</p>
					) : null}
					{children}
					<NeedHelp
						maxWidth={576}
						email={accountManager?.email ?? "info@futureforex.co.za"}
						phone={accountManager?.contact_number ?? "021 518 0558"}
					>
						Need help with this?
					</NeedHelp>
				</main>
			)}
			{showTutorial && (
				<TutorialStep
					title="Onboarding steps"
					target="#step-0"
					onContinue={() => disable()}
				>
					{variant === "individual"
						? "Track your progress and navigate between steps here."
						: "Track your company’s progress and navigate between steps here."}
				</TutorialStep>
			)}
			{showResumeLaterModal && (
				<LogoutResumeLaterModal
					onBack={() => setShowResumeLaterModal(false)}
					onClose={() => setShowResumeLaterModal(false)}
				/>
			)}
		</SignedInLayout>
	);
};
