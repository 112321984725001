import { ApiErrors } from "@app/components/api-errors";
import { FieldError } from "@app/components/field-error";
import {
	ReactNode,
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useState,
} from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Card } from "../card";

import { Dropdown } from "@app/components/dropdown";
import { Input } from "@app/components/input";
import { Label } from "@app/components/label";
import { fetcher } from "@app/fetcher";
import { api } from "@app/services";
import { FormErrors } from "@app/utils/get-form-errors";
import { preload } from "swr";
import {
	RegistrationReferrer,
	useRegistrationReferrers,
} from "../individual-additional-details/use-registration-referrers";
import { Row } from "../row";
import {
	AdditionalInformation,
	useAdditionalInformation,
} from "./use-additional-information";

type Inputs = {
	registration_referrer_id: RegistrationReferrer | undefined;
	referrer_name: string;
};

export const AdditionalInformationForm = forwardRef<
	any,
	{
		activeClientId: number;
		onSavingChanges: (isSavingChanges: boolean) => void;
		onSaved: (isSaved: boolean) => void;
	}
>(({ activeClientId, onSaved, onSavingChanges }, ref) => {
	const refName = window.localStorage.getItem("refName");
	const refId = window.localStorage.getItem("ref");

	const { data: registrationReferrers } = useRegistrationReferrers();
	const { submit, update } = useAdditionalInformation(activeClientId);
	const [hideReferral, setHideReferral] = useState(!!refId);

	const [apiErrors, setApiErrors] = useState<ReactNode[]>([]);
	const {
		register,
		handleSubmit,
		watch,
		setValue,
		setError,
		getValues,
		clearErrors,
		formState: { errors },
	} = useForm<Inputs>({
		defaultValues: async () => {
			const { data } = await api.get<AdditionalInformation>(
				`/onboarding/${activeClientId}/company-additional-information/`,
			);
			const registrationReferrer = await preload<RegistrationReferrer[]>(
				"onboarding/registration-referrers/",
				fetcher,
			);
			return {
				registration_referrer_id: registrationReferrer.find((current) =>
					refId
						? Number.parseInt(refId, 10) === current.id
						: current.id === data.registration_referrer_id,
				),
				referrer_name: refName ?? data.referrer_name,
			} as Inputs;
		},
	});

	const clearApiFieldErrors = useCallback(() => {
		for (const key of Object.keys(errors)) {
			if (errors[key as keyof Inputs]?.type === "api")
				clearErrors(key as keyof Inputs);
		}
	}, [clearErrors, errors]);

	const handleSetErrors = useCallback(
		(errors: FormErrors) => {
			for (const current of errors.fieldErrors) {
				setError(current.name as keyof Inputs, {
					type: "api",
					message: current.message,
				});
			}
			setApiErrors(errors.apiErrors);
		},
		[setError],
	);

	const onSubmit: SubmitHandler<Inputs> = useCallback(
		async (data) => {
			if (!data.registration_referrer_id) {
				setError("registration_referrer_id", {
					type: "required",
					message: "This field is required",
				});
				return;
			}
			clearApiFieldErrors();
			onSavingChanges(true);

			const errors = await submit({
				registration_referrer_id: data.registration_referrer_id.id,
				referrer_name: data.referrer_name || undefined,
			});
			if (errors) {
				handleSetErrors(errors);
				return false;
			}

			onSaved(true);
			onSavingChanges(false);
			return true;
		},
		[
			submit,
			onSaved,
			onSavingChanges,
			clearApiFieldErrors,
			handleSetErrors,
			setError,
		],
	);

	useImperativeHandle(
		ref,
		() => {
			return {
				submit: async () => onSubmit(getValues()),
			};
		},
		[onSubmit, getValues],
	);

	const handlePartialSave = async () => {
		const values = getValues();
		const data = {
			registration_referrer_id: values.registration_referrer_id?.id,
			referrer_name: values.referrer_name,
		};
		if (Object.keys(data).length === 0) return;
		onSavingChanges(true);
		const errors = await update(data);
		setApiErrors([]);
		if (errors) {
			handleSetErrors(errors);
		} else {
			onSaved(true);
		}
		onSavingChanges(false);
	};

	useEffect(() => {
		if (registrationReferrers && refId) {
			const match = registrationReferrers.find(
				(current) => current.id === Number.parseInt(refId),
			);
			if (!match || (match.requires_detail && !refName)) {
				setHideReferral(false);
				setValue("registration_referrer_id", undefined);
				setValue("referrer_name", "");
			}
		}
	}, [registrationReferrers, refId, refName, setValue]);

	return (
		<Card title="Additional information" hide={hideReferral}>
			<form id="additional" onSubmit={handleSubmit(onSubmit)}>
				<Row>
					<div>
						<Label htmlFor="registration_referrer_id">
							How did you hear about us?*
						</Label>
						<Dropdown
							onChange={(event) => {
								setValue("registration_referrer_id", event.value);
								if (event.value) {
									clearErrors("registration_referrer_id");
									if (!event.value.requires_detail)
										clearErrors("referrer_name");
									handlePartialSave();
								}
							}}
							value={watch("registration_referrer_id")}
							invalid={!!errors.registration_referrer_id}
							placeholder="Select an option"
							optionLabel="name"
							options={registrationReferrers ?? []}
						/>
						{errors.registration_referrer_id && (
							<FieldError>{errors.registration_referrer_id.message}</FieldError>
						)}
					</div>
				</Row>
				{watch("registration_referrer_id")?.requires_detail && (
					<Row>
						<div>
							<Label htmlFor="referrer_name">Referrer name</Label>
							<Input
								aria-invalid={!!errors.referrer_name}
								placeholder="Enter full name"
								{...register("referrer_name", {
									required: "Please enter your referrer’s full name",
									onBlur: handlePartialSave,
								})}
							/>
							{errors.referrer_name && (
								<FieldError>{errors.referrer_name.message}</FieldError>
							)}
						</div>
					</Row>
				)}
				<ApiErrors errors={apiErrors} />
			</form>
		</Card>
	);
});
