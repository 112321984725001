import { Button } from "@app/components/button";
import { DocumentUploadModal } from "@app/components/document-upload-modal";
import { IconButton } from "@app/components/icon-button";
import { MoreInfoTooltip } from "@app/components/more-info-tooltip";
import { useMediaQuery } from "@app/hooks/use-media-query";
import {
	ComponentPropsWithoutRef,
	ReactNode,
	useEffect,
	useState,
} from "react";
import styles from "./index.module.css";
import {
	OnboardingDocumentType,
	useDocuments,
	Document,
} from "./use-documents";

import { FieldError } from "@app/components/field-error";
import { abortRequests } from "@app/services";
import { getFormErrors } from "@app/utils/get-form-errors";
import { AxiosProgressEvent } from "axios";
import clsx from "clsx";
import { FieldError as RHFFieldError } from "react-hook-form";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import uploadedDocumentSrc from "./uploaded-document.svg";
import documentIconSrc from "./document.svg";
import { formatFileSize } from "@app/utils";

const getErrorMessage = (str?: string) => {
	if (str === "field required") return "This document is required";
	return str;
};

export const NewDocumentField = ({
	type,
	label,
	value,
	onChange,
	tooltip,
	error,
	tooltipWidth = 260,
	disabled,
	className,
	multiple = false,
	...rest
}: {
	type: OnboardingDocumentType;
	label: string;
	value?: string | null;
	onChange?: () => Promise<void>;
	tooltip?: ReactNode;
	error?: RHFFieldError;
	tooltipWidth?: number;
	disabled?: boolean;
	className?: string;
	multiple?: boolean;
} & Omit<ComponentPropsWithoutRef<"div">, "onChange">) => {
	const [uploadedFiles, setUploadedFiles] = useState<Array<File>>([]);
	const [removeId, setRemoveId] = useState<string | null>(null);
	const [downloadId, setDownloadId] = useState<string | null>(null);
	const [files, setFiles] = useState<Array<Document>>([]);
	const isMobile = useMediaQuery();
	const {
		uploadDocument,
		downloadDocument,
		mutate,
		data,
		deleteDocument,
		isLoading,
	} = useDocuments(type);
	const [showUpload, setShowUpload] = useState(false);

	const handleRemove = async (documentId: string) => {
		setRemoveId(documentId);
		await deleteDocument(documentId);
		await mutate();
		await onChange?.();
		setRemoveId(null);
	};

	const handleDownload = async (documentId: string) => {
		try {
			setDownloadId(documentId);
			await downloadDocument(documentId);
		} catch (error) {
			// TODO: handle error
		} finally {
			setDownloadId(null);
		}
	};

	useEffect(() => {
		if (!data) return;
		const filteredata = data.filter((file) => file.document_type === type);
		setFiles(filteredata);
	}, [data, type]);

	const hasFiles = files.length > 0;

	return (
		<>
			<div {...rest} className={clsx(styles.wrapper, className)}>
				<div
					className={styles.container}
					data-error={!!error}
					data-active={!!data}
				>
					<div className={styles.title}>
						<img
							className={styles.icon}
							src={hasFiles ? uploadedDocumentSrc : documentIconSrc}
							width={24}
							height={24}
							alt=""
						/>
						<span>{label}*</span>
						{tooltip && (
							<MoreInfoTooltip
								name={label}
								className={styles.trigger}
								hasIcon
								maxWidth={tooltipWidth}
							>
								{tooltip}
							</MoreInfoTooltip>
						)}
					</div>

					{isMobile ? (
						<IconButton
							className={styles.addButton}
							disabled={disabled || isLoading}
							onClick={() => setShowUpload(true)}
						>
							<FiPlus size={24} color="#56A7A2" />
						</IconButton>
					) : (
						<Button
							className={styles.addButton}
							disabled={disabled || isLoading}
							onClick={() => setShowUpload(true)}
						>
							Add file{multiple ? "(s)" : ""}
						</Button>
					)}

					{hasFiles && (
						<ul className={styles.uploadedFiles}>
							{files.map((current) => {
								const fileMatch = uploadedFiles.find(
									(file) => file.name === current.name,
								);
								return (
									<li className={styles.row} key={current.document_id}>
										{isMobile && (
											<li className={styles.name}>{current.name}</li>
										)}
										<ul className={styles.fileInformation}>
											{!isMobile && (
												<>
													<li className={styles.name}>{current.name}</li>
													<li className={styles.divider} />
												</>
											)}

											{fileMatch && (
												<>
													<li>{formatFileSize(fileMatch.size)}</li>
													<li className={styles.divider} />
												</>
											)}

											<li>
												<button
													className={styles.button}
													type="button"
													disabled={downloadId === current.document_id}
													onClick={() => handleDownload(current.document_id)}
												>
													{downloadId === current.document_id
														? "Downloading"
														: "Download"}
												</button>
											</li>
											{!isMobile && (
												<>
													<li className={styles.divider} />
													<li>
														<button
															className={styles.button}
															type="button"
															disabled={removeId === current.document_id}
															onClick={() => handleRemove(current.document_id)}
														>
															{removeId === current.document_id
																? "Removing"
																: "Remove"}
														</button>
													</li>
												</>
											)}
										</ul>

										{isMobile && (
											<IconButton
												className={styles.removeButton}
												onClick={() => handleRemove(current.document_id)}
												disabled={disabled}
											>
												<FiTrash2 size={24} color="#56A7A2" />
											</IconButton>
										)}
									</li>
								);
							})}
						</ul>
					)}
				</div>
				{error && <FieldError>{getErrorMessage(error.message)}</FieldError>}
			</div>
			{showUpload && (
				<DocumentUploadModal
					multiple
					maxFiles={25}
					showSingleButton
					onClose={() => setShowUpload(false)}
					onConfirmUpload={async () => {
						await onChange?.();
						setShowUpload(false);
					}}
					onCancelUpload={() => {
						abortRequests();
					}}
					onDeleteItem={(index) => {
						if (data) {
							handleRemove(files[index].document_id);
						}
					}}
					onUpload={async (
						index: number,
						file: File | undefined,
						onSetUploadComplete: (index: number, id?: number | string) => void,
						onSetUploadProgress: (progressEvent: AxiosProgressEvent) => void,
						onUploadError: (error: string, index: number) => void,
					) => {
						if (!file) return;
						try {
							await uploadDocument(file, onSetUploadProgress);
							setUploadedFiles((files) => [...files, file]);
							onSetUploadComplete(index);
							mutate();
						} catch (error) {
							if (error) {
								const { fieldErrors, apiErrors } = getFormErrors(error);
								if (apiErrors.length > 0) {
									onUploadError(apiErrors[0], index);
									return;
								}

								if (fieldErrors.length > 0) {
									onUploadError(fieldErrors[0].message, index);
									return;
								}

								onUploadError("Upload failed", index);
							}
						}
					}}
				/>
			)}
		</>
	);
};
