import {
	ReactNode,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { OnboardingFooter } from "../onboarding-footer";
import { OnboardingLayout } from "../onboarding-layout";
import { EntityForm } from "./entity-form";
import {
	LinkableRelatedEntities,
	RelatedEntityVariant,
	useRelatedEntities,
} from "./use-related-entities";

import styles from "./index.module.css";

import { ApiErrors } from "@app/components/api-errors";
import { Button } from "@app/components/button";
import { Checkbox } from "@app/components/checkbox";
import { Dialog } from "@app/components/dialog";
import { FieldError } from "@app/components/field-error";
import { ProcessingModal } from "@app/components/processing-modal";
import { paths } from "@app/constants/paths";
import { useClientProfile } from "@app/hooks/use-client-profile";
import { handleScrollToFirstError } from "@app/hooks/use-form-errors";
import { useLocalStorage } from "@app/hooks/use-local-storage";
import { useOnboardingStatus } from "@app/hooks/use-onboarding-status";
import { sleep } from "@app/utils/sleep";
import { useNavigate } from "react-router-dom";
import { IncompleteInformationModal } from "../incomplete-information-modal";
import { AdditionalInformationForm } from "./additional-information-form";
import { InitEntityFrom } from "./init-entity-from";
import plusSrc from "./plus.svg";
import { ShareholderLegalEntityForm } from "./shareholder-legal-entity-form";

const getDetailsForVariant = (variant: RelatedEntityVariant) => {
	if (variant === "director") {
		return {
			step: 2,
			singular: "director",
			plural: "directors",
			backPath: paths().onboarding.business.companyDetails,
			nextPath: paths().onboarding.business.signatories,
		};
	}

	if (variant === "authorised_signatory") {
		return {
			step: 3,
			singular: "signatory",
			plural: "signatories",
			backPath: paths().onboarding.business.directors,
			nextPath: paths().onboarding.business.shareholders,
		};
	}

	if (variant === "shareholder") {
		return {
			step: 4,
			singular: "shareholder",
			plural: "shareholders",
			backPath: paths().onboarding.business.signatories,
		};
	}
	return {};
};

const toTitleCase = (str?: string) =>
	str ? str.charAt(0).toUpperCase() + str.substr(1) : undefined;

export const RelatedEntities = ({
	variant = "director",
	activeClientId,
}: {
	variant?: RelatedEntityVariant;
	activeClientId: number;
}) => {
	const [entitiesAddedThisSession, setEntitiesAddedThisSession] = useState<
		Array<number>
	>([]);
	const [isShareholderStructureRequired, setIsShareholderStructureRequired] =
		useState<boolean | null>(null);
	const [tempEntities, setTempEntities] = useLocalStorage<Array<number>>(
		`${activeClientId}-${variant}-entities`,
		[],
	);
	const [showSuccess, setShowSuccess] = useState(false);
	const [isUpdating, setUpdating] = useState(false);
	const [targetPath, setTargetPath] = useState<string | null>(null);
	const [apiErrors, setApiErrors] = useState<ReactNode[]>([]);
	const [showConfirmError, setShowConfirmError] = useState(false);
	const [hasConfirmed, setHasConfirmed] = useState(false);
	const [showGeneralError, setShowGeneralError] = useState(false);
	const [isSaved, setIsSaved] = useState(false);
	const [isSubmitting, setSubmitting] = useState(false);
	const [isFinalSubmission, setIsFinalSubmission] = useState(false);
	const [isSavingChanges, setIsSavingChanges] = useState(false);
	const navigate = useNavigate();
	const [entityToRemove, setEntityToRemove] =
		useState<LinkableRelatedEntities | null>(null);

	const refs = useRef<Record<string, any>>({});

	const { step, singular, plural, backPath, nextPath } =
		getDetailsForVariant(variant);

	const {
		data,
		entitiesForVariant,
		mutate,
		submit,
		isLoading,
		createEntity,
		deleteEntity,
		linkableEntities,
		allLinkableEntities,
		getPathForEntity,
		createEntityLink,
		submitOnboarding,
	} = useRelatedEntities(variant);
	const { data: clientProfile } = useClientProfile();
	const { formProgress } = useOnboardingStatus(activeClientId);

	const showEntities =
		variant === "shareholder" ? isShareholderStructureRequired === false : true;

	const handleSubmit = async () => {
		setIsSavingChanges(true);
		setSubmitting(true);

		const allRefs = Object.values(refs.current).filter(Boolean);
		const results = await Promise.all(allRefs.map((ref) => ref?.submit()));
		const isSuccessful = results.every((isSuccess) => isSuccess);

		if (!hasConfirmed && showEntities) {
			setShowConfirmError(true);
			setIsSavingChanges(false);
			setIsSaved(true);
			setSubmitting(false);
			handleScrollToFirstError();
			return;
		}

		if (isSuccessful) {
			const errors = await submit();
			if (errors?.apiErrors) {
				setApiErrors(errors?.apiErrors);
			} else if (nextPath) {
				navigate(nextPath);
			} else if (isPrevStepsCompleted) {
				const startTime = Date.now();
				setIsFinalSubmission(true);
				const errors = await submitOnboarding();
				if (errors) {
					setApiErrors(errors.apiErrors);
				} else {
					window.localStorage.removeItem("ref");
					window.localStorage.removeItem("refName");
					const pastTime = Date.now() - startTime;
					if (pastTime < 2000) {
						await sleep(2000 - pastTime);
					}
					setShowSuccess(true);
					setTimeout(() => {
						window.location.href = paths().dashboard;
					}, 3500);
				}
				setIsFinalSubmission(false);
			} else {
				const completedSteps =
					formProgress?.awaiting_submission_details.steps_completed ?? [];
				let path = paths().onboarding.business.shareholders;
				if (!completedSteps.includes("basic_details")) {
					path = paths().onboarding.business.companyBasics;
				}
				if (!completedSteps.includes("company_details")) {
					path = paths().onboarding.business.companyDetails;
				}
				if (!completedSteps.includes("directors_details")) {
					path = paths().onboarding.business.directors;
				}
				if (!completedSteps.includes("signatories_details")) {
					path = paths().onboarding.business.signatories;
				}
				navigate(path);
			}
		}

		setIsSavingChanges(false);
		setIsSaved(true);
		setSubmitting(false);
	};

	const handleAdd = useCallback(async () => {
		setHasConfirmed(false);
		if (linkableEntities.length === 0) {
			const entityId = await createEntity("individual");
			if (entityId) {
				setEntitiesAddedThisSession((entitiesAddedThisSession) => [
					...entitiesAddedThisSession,
					entityId,
				]);
			}
		} else {
			setTempEntities([...tempEntities, Date.now()]);
		}
	}, [setTempEntities, tempEntities, createEntity, linkableEntities]);

	const isInProgress = isLoading || isUpdating;

	useEffect(() => {
		if (data) {
			const isCompleted = data[
				`${variant}_completed` as keyof typeof data
			] as boolean;
			setHasConfirmed(isCompleted);
		}
	}, [data, variant]);

	useEffect(() => {
		const isEmpty =
			entitiesForVariant === null || entitiesForVariant.length === 0;
		const isNoShareholderStructureRequired =
			variant === "shareholder"
				? isShareholderStructureRequired === false
				: true;
		if (data && !isInProgress && isEmpty && isNoShareholderStructureRequired) {
			if (linkableEntities.length === 0) {
				createEntity("individual").then((entityId) => {
					if (entityId) {
						setEntitiesAddedThisSession((entitiesAddedThisSession) => [
							...entitiesAddedThisSession,
							entityId,
						]);
					}
				});
			} else {
				setTempEntities([Date.now()]);
			}
		}
	}, [
		data,
		entitiesForVariant,
		isInProgress,
		isShareholderStructureRequired,
		variant,
		setTempEntities,
		createEntity,
		linkableEntities,
	]);

	const handleYes = async (entityId: number, id: number) => {
		setUpdating(true);
		const lastLinkableEntityUsed = linkableEntities.length <= 1;
		if (lastLinkableEntityUsed) {
			const entityIds = await Promise.all(
				tempEntities.map((current) =>
					current === id
						? createEntityLink(entityId)
						: createEntity("individual"),
				),
			);
			setEntitiesAddedThisSession((entitiesAddedThisSession) => [
				...entitiesAddedThisSession,
				...(entityIds.filter(Boolean) as Array<number>),
			]);
			setTempEntities([]);
		} else {
			await createEntityLink(entityId);
			setTempEntities(tempEntities.filter((i) => i !== id));
		}
		await mutate();
		setUpdating(false);
	};

	const isPrevStepsCompleted = useMemo(() => {
		if (!formProgress) return false;
		if (variant !== "shareholder") return false;
		const prevSteps = [
			"basic_details",
			"company_details",
			"directors_details",
			"signatories_details",
		];
		return prevSteps.every((step) =>
			formProgress.awaiting_submission_details.steps_completed.includes(step),
		);
	}, [formProgress, variant]);

	const handleNavigate = async (path: string, id?: string) => {
		if (id) {
			const elem = document.querySelector(`#entity-${id}`);
			if (elem) {
				elem.scrollIntoView({ behavior: "smooth" });
			}
			return;
		}

		const allRefs = Object.values(refs.current).filter(Boolean);
		const results = await Promise.all(allRefs.map((ref) => ref?.submit()));

		const isSuccessful = results.every((isSuccess) => isSuccess);
		if (!isSuccessful) {
			setTargetPath(path);
		} else {
			navigate(path);
		}
	};

	const handleRemoveAll = async () => {
		await Promise.all(
			entitiesForVariant.map((entity) =>
				deleteEntity(entity.id, entity.related_entity_id),
			),
		);
		setTempEntities([]);
		mutate();
	};

	const handleRemove = async (entity: LinkableRelatedEntities) => {
		setUpdating(true);
		const result = await deleteEntity(
			entity.id,
			entity.is_editable ? entity.related_entity_id : undefined,
		);
		delete refs.current[entity.id];
		setHasConfirmed(false);
		await mutate();
		setUpdating(false);
		return result;
	};

	const count = entitiesForVariant.length + tempEntities.length;
	const showAdditionalInformationForm =
		variant === "shareholder" &&
		activeClientId &&
		clientProfile?.lead_type === "inbound";

	const footer = (
		<>
			<button type="button" className={styles.addButton} onClick={handleAdd}>
				<img src={plusSrc} alt="" width={20} height={20} />
				Add another {singular}
			</button>

			<div className={styles.confirmCheckboxContainer}>
				<div className={styles.checkbox}>
					<Checkbox
						error={showConfirmError}
						id="confirm-count"
						value={hasConfirmed}
						onChange={(value: boolean) => {
							if (value) setShowConfirmError(false);
							setHasConfirmed(value);
						}}
					/>
					<label htmlFor="confirm-count">
						I confirm the company has{" "}
						<strong>
							{count} {count > 1 ? plural : singular}
						</strong>
						*{variant === "director" && " (with more than 5% share)"}
					</label>
				</div>
				{showConfirmError && <FieldError>This field is required</FieldError>}
			</div>

			<ApiErrors className={styles.apiErrors} errors={apiErrors} />
		</>
	);

	const canRemove = count > 1;

	return (
		<OnboardingLayout
			tempEntities={tempEntities}
			step={step}
			variant="legal_entity"
			onStepNavigate={handleNavigate}
			showError={showGeneralError}
		>
			{variant === "shareholder" && activeClientId && (
				<ShareholderLegalEntityForm
					activeClientId={activeClientId}
					onShowShareholderStructureChange={(isComplex) => {
						setIsShareholderStructureRequired(isComplex);
						if (isComplex) handleRemoveAll();
					}}
					ref={(ref) => {
						refs.current["shareholderLegalEntity"] = ref;
					}}
				/>
			)}
			{showEntities && (
				<>
					{entitiesForVariant.map((entity, index) => {
						const showFooter =
							index === entitiesForVariant.length - 1 &&
							tempEntities.length === 0;
						if (!activeClientId) return null;
						return (
							<EntityForm
								onGeneralError={() => setShowGeneralError(true)}
								onParentSave={() => mutate()}
								entitiesAddedThisSession={entitiesAddedThisSession}
								ref={(ref) => {
									refs.current[entity.id] = ref;
								}}
								onGoToEntity={async (id) => {
									const path = await getPathForEntity(id, variant);
									if (path) handleNavigate(path);
								}}
								linkableEntities={linkableEntities}
								allLinkableEntities={allLinkableEntities}
								activeClientId={activeClientId}
								key={entity.id}
								id={entity.related_entity_id}
								linkId={entity.id}
								index={index}
								variant={variant}
								onSavingChanges={setIsSavingChanges}
								onCreateEntity={async () => {
									setUpdating(true);
									await deleteEntity(
										entity.id,
										entity.is_editable ? entity.related_entity_id : undefined,
									);
									const entityId = await createEntity("individual");
									if (entityId) {
										setEntitiesAddedThisSession((entitiesAddedThisSession) => [
											...entitiesAddedThisSession,
											entityId,
										]);
									}
									await mutate();
									setUpdating(false);
								}}
								onRelink={async (entityId) => {
									if (!entityId) return;
									setUpdating(true);
									await deleteEntity(
										entity.id,
										entity.is_editable ? entity.related_entity_id : undefined,
									);
									await createEntityLink(entityId);
									await mutate();
									setUpdating(false);
								}}
								onSaved={setIsSaved}
								onRemove={
									!canRemove
										? undefined
										: (isDirtyForm: boolean) => {
												if (isDirtyForm) {
													setEntityToRemove(entity);
												} else {
													handleRemove(entity);
												}
											}
								}
								isLink={!entity.is_editable}
								footer={showFooter && footer}
							/>
						);
					})}

					{tempEntities.map((id, index) => {
						const showFooter = index === tempEntities.length - 1;
						return (
							<InitEntityFrom
								linkableEntities={linkableEntities}
								variant={variant}
								key={id}
								id={id}
								index={index + entitiesForVariant.length}
								ref={(ref) => {
									refs.current[id] = ref;
								}}
								onNo={async () => {
									setUpdating(true);
									const entityId = await createEntity("individual");
									if (entityId) {
										setEntitiesAddedThisSession((entitiesAddedThisSession) => [
											...entitiesAddedThisSession,
											entityId,
										]);
									}
									await mutate();
									setTempEntities(tempEntities.filter((i) => i !== id));
									setUpdating(false);
								}}
								onYes={(entityId) => handleYes(entityId, id)}
								onRemove={
									!canRemove
										? undefined
										: () => {
												setTempEntities(tempEntities.filter((i) => i !== id));
											}
								}
								footer={showFooter && footer}
							/>
						);
					})}
				</>
			)}
			{showAdditionalInformationForm && (
				<AdditionalInformationForm
					ref={(ref) => {
						refs.current["additionalInformation"] = ref;
					}}
					activeClientId={activeClientId}
					onSaved={setIsSaved}
					onSavingChanges={setIsSavingChanges}
				/>
			)}
			<OnboardingFooter
				onBack={() => {
					if (backPath) {
						navigate(backPath);
					}
				}}
				isSubmitting={isSubmitting}
				formId="company-basics"
				onSubmit={handleSubmit}
				primaryText={isPrevStepsCompleted ? "Submit" : "Next"}
				isSaving={isSavingChanges}
				hasSaved={isSaved}
			/>

			{!!targetPath && (
				<IncompleteInformationModal
					path={targetPath}
					onCancel={() => setTargetPath("")}
				/>
			)}
			<ProcessingModal
				successText="Form submitted"
				processingText="Submitting form"
				isOpen={isFinalSubmission || showSuccess}
				showSuccess={showSuccess}
			/>
			<Dialog
				isOpen={!!entityToRemove}
				title={`Remove ${toTitleCase(singular)}`}
				size="sm"
				onClose={() => setEntityToRemove(null)}
				description={`Are you sure you want to remove this ${toTitleCase(singular)}?`}
				actions={
					<>
						<Button variant="secondary" onClick={() => setEntityToRemove(null)}>
							Back
						</Button>
						<Button
							onClick={() => {
								if (entityToRemove) handleRemove(entityToRemove);
								setEntityToRemove(null);
							}}
						>
							Remove
						</Button>
					</>
				}
			/>
		</OnboardingLayout>
	);
};
