import { Button } from "@app/components/button";
import { FormBuilder } from "@app/components/form-builder";

import type { ViewProperties } from "./properties";

import { Dialog } from "@app/components/dialog";
import { useMediaQuery } from "@app/hooks/use-media-query";

import styles from "./index.module.css";

export const AddEditBankAccountModalView = (props: ViewProperties) => {
	const isMobile = useMediaQuery();
	return (
		<Dialog
			onBack={isMobile ? undefined : props.onBack}
			isOpen={props.isOpen}
			onClose={props.onClose}
			fullscreen={isMobile}
			showTopbar={isMobile}
			title={props.isEdit ? "Edit bank account" : "Add bank account"}
			actions={
				<>
					<Button variant="secondary" onClick={props.onBack}>
						Back
					</Button>
					<Button form="add-edit-bank-account-form" type="submit">
						Save
					</Button>
				</>
			}
		>
			<form
				id="add-edit-bank-account-form"
				onSubmit={props.handleSubmit(props.onHandleSubmitValid)}
				noValidate
			>
				<div>
					<p className={styles.title}>Account Holder</p>
					<p className={styles.subtitle}>
						You can only withdraw to a bank account in your name.
					</p>
					<FormBuilder
						formControl={props.formControl}
						errors={props.errors}
						formInputs={props.formInputs}
						mappedReasons={props.mappedReasons}
					/>
				</div>
			</form>
		</Dialog>
	);
};
