import { useState } from "react";

import { useMediaQuery } from "@app/hooks/use-media-query";
import { IconButton } from "@app/components/icon-button";
import { InputSwitch } from "@app/components/input-switch";
import { Button } from "@app/components/button";
import { RadioGroup } from "@app/components/radio-group";
import PenIcon from "@app/assets/images/pen-icon.svg";

import styles from "../index.module.css";

const DELIVERY_METHOD_OPTIONS = [
	{ label: "SMS", value: "sms" },
	{ label: "Whatsapp", value: "whatsapp" },
];

export const OtpAuthentication = () => {
	const [deliveryMethod, setDeliveryMethod] = useState("sms");
	const [showEnableOTPModal, setShowEnableOTPModal] = useState(false);
	const [showOTPNumberModal, setShowOTPNumberModal] = useState(true);
	const isMobile = useMediaQuery();

	const otpData = {
		enabled: false,
		deliveryMethod: "sms",
	};

	const handleEnableOTPModal = () => setShowEnableOTPModal(!showEnableOTPModal);
	const handleOTPNumberModal = () => setShowOTPNumberModal(!showOTPNumberModal);

	const handleChangeDeliveryMethod = (value: string) => {
		console.log({ value }); //TODO: Implement delivery method change
		setDeliveryMethod(value);
	};

	return (
		<>
			<div className={styles.subtitleContainer}>
				<h3 className={styles.subtitle}>OTP Authentication</h3>
				<div className={styles.inputSwitch}>
					<InputSwitch
						id="otpAuthentication"
						checked={showEnableOTPModal}
						onChange={handleEnableOTPModal}
					/>
					<label
						className={styles.inputSwitchLabel}
						htmlFor="otpAuthentication"
					>
						{otpData.enabled ? "Enabled" : "Disabled"}
					</label>
				</div>
			</div>

			{showEnableOTPModal && (
				<div className={styles.settingsRow}>
					<div>
						<p className={styles.settingsLabel}>
							Mobile Number (used for OTPs)
						</p>
						<p className={styles.settingsValue}>+27 75 657 8910</p>
					</div>
					{isMobile ? (
						<IconButton onClick={handleOTPNumberModal}>
							<img src={PenIcon} alt="edit-icon" />
						</IconButton>
					) : (
						<Button
							className={styles.settingsButton}
							onClick={handleOTPNumberModal}
							variant="secondary"
						>
							Change OTP number
						</Button>
					)}
				</div>
			)}

			<div className={styles.settingsRow}>
				<div>
					<p className={styles.settingsLabel}>Default OTP Delivery Method</p>
					<RadioGroup
						name="delivery-method"
						value={deliveryMethod}
						onChange={handleChangeDeliveryMethod}
						options={DELIVERY_METHOD_OPTIONS}
					/>
				</div>
			</div>
		</>
	);
};
