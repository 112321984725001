import { ErrorBoundary } from "@sentry/react";
import { GeneralError } from "./features/errors/general-error";
import { MetaTags } from "./meta-tags";
import { Providers } from "./providers";
import { Router } from "./router";

const Main = () => (
	<Providers>
		<MetaTags />
		<ErrorBoundary fallback={<GeneralError />}>
			<Router />
		</ErrorBoundary>
	</Providers>
);

export default Main;
