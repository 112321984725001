import { type ReactNode } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./index.module.css";
interface MobileHeaderProps {
	children: ReactNode;
	hasBackButton?: boolean;
	onBack?: () => void;
	variant?: "sticky" | "absolute" | "fixed";
	rightSection?: ReactNode;
	leftSection?: ReactNode;
}

export const MobileHeader = ({
	children,
	hasBackButton = false,
	onBack,
	variant = "sticky",
	rightSection,
	leftSection,
}: MobileHeaderProps) => {
	const navigate = useNavigate();
	const handleBack = () => navigate(-1);

	const showBack = hasBackButton || onBack;
	return (
		<header data-variant={variant} className={styles.container}>
			{leftSection ? (
				leftSection
			) : showBack ? (
				<button
					className={styles.backButton}
					type="button"
					data-testid="back"
					aria-label="Back"
					onClick={onBack ?? handleBack}
				>
					<svg
						role="presentation"
						xmlns="http://www.w3.org/2000/svg"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
					>
						<path
							d="M19 12H5M5 12L12 19M5 12L12 5"
							stroke="#D1D1D1"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</button>
			) : null}

			<h1 className={styles.title}>{children}</h1>
			{rightSection ? (
				<div className={styles.rightSection}>{rightSection}</div>
			) : showBack || leftSection ? (
				<div className={styles.placeholder} />
			) : null}
		</header>
	);
};
