import { ReactNode, useEffect, useState } from "react";

import { FormBuilderProps } from "@app/components/form-builder";
import { MappedReasons } from "@app/services";
import { Control, FieldErrors, UseFormTrigger } from "react-hook-form";
import { RecipientAddressForm } from "./components-responsive/recipient-address-form";
import { RecipientDetailsForm } from "./components-responsive/recipient-details-form";
import { RecipientTypeSelection } from "./components-responsive/recipient-type-selection";
import { Layout } from "./layout";

export const AddEditRecipientsViewResponsive = (props: {
	addressFormInputs: FormBuilderProps.FormInputProps[][];
	apiErrors?: ReactNode[];
	detailsFormInputs: FormBuilderProps.FormInputProps[][];
	errors?: FieldErrors<any>;
	isSubmitting?: boolean;
	formControl: Control<any, any>;
	isEdit: boolean;
	isIndividual: boolean;
	isValid: boolean;
	isDirty: boolean;
	recipientTypeOptions: Array<{
		type: string;
		label: string;
		icon: string;
	}>;
	loading?: boolean;
	mappedReasons?: MappedReasons;
	recipientType?: string;
	title: string;
	trigger: UseFormTrigger<any>;
	onBack: () => void;
	onCancel: () => void;
	variant: "default" | "payment-details";
	onSelectRecipientType: (recipientType: string) => void;
}) => {
	const [step, setStep] = useState<number>(0);
	const [showRecipientError, setShowRecipientError] = useState(false);

	useEffect(() => {
		if (props.isEdit) {
			setStep(1);
		}
	}, [props.isEdit]);

	const onBack = () => {
		if (props.isEdit) {
			props.onCancel();
		} else if (step === 0) {
			props.onCancel();
		} else {
			if (step === 1) {
				setStep(0);
			} else {
				setStep(1);
			}
		}
	};

	const onNext = async () => {
		setShowRecipientError(false);
		if (step === 0) {
			if (!props.recipientType) {
				setShowRecipientError(true);
				return;
			}
			setStep(1);
			return;
		}
	};

	return (
		<Layout
			variant={props.variant}
			isEdit={props.isEdit}
			isDisabled={!!props.isSubmitting}
			step={step}
			onBack={() => onBack()}
			onNext={() => onNext()}
		>
			<div className="p-6 pb-30">
				{props.isEdit && (
					<>
						<RecipientTypeSelection {...props} editMode />
						<hr className="mb-6" />
						<RecipientDetailsForm {...props} showError={false} />
						<hr className="mb-6" />
						<RecipientAddressForm showError={false} {...props} />
					</>
				)}
				{!props.isEdit && (
					<>
						{step === 0 && (
							<RecipientTypeSelection
								error={
									showRecipientError
										? "Select an option above to continue"
										: undefined
								}
								{...props}
								onSelectRecipientType={(type) => {
									setShowRecipientError(false);
									props.onSelectRecipientType(type);
								}}
							/>
						)}
						{step === 1 && (
							<>
								<RecipientDetailsForm {...props} showError={false} />
								<hr className="mb-6" />
								<RecipientAddressForm {...props} showError={false} />
							</>
						)}
					</>
				)}
			</div>
		</Layout>
	);
};
