import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Button } from "@app/components/button";
import { FadeIn } from "@app/components/fade-in";
import { Title } from "@app/components/title";
import { paths } from "@app/constants/paths";
import { useResendConfirmationEmail } from "@app/helpers/auth";
import { useShowToast } from "@app/helpers/toast";
import type { RootState } from "@app/redux";

import { ApiErrors } from "@app/components/api-errors";
import { ResendEmail } from "../resend-email";
import styles from "./index.module.css";

const NotVerified = () => {
	const navigate = useNavigate();
	const [resendConfirmationEmail] = useResendConfirmationEmail();
	const [showToast] = useShowToast();

	const { unverifiedEmail } = useSelector(
		(rootState: RootState) => rootState.auth,
	);

	const [apiErrors, setApiErrors] = useState<string[]>([]);

	const email =
		window.localStorage.getItem("register-email") ?? unverifiedEmail;

	const onLogin = async () => navigate(paths().login);

	const handleResend = () => {
		if (!email) {
			showToast("No email found", "error");
			navigate(paths().login);
			return Promise.resolve(false);
		}
		return new Promise<boolean>((resolve) => {
			resendConfirmationEmail(email, (errors?: string[] | undefined) => {
				if (errors && errors.length > 0) {
					setApiErrors(errors);
					resolve(false);
				} else {
					setApiErrors([]);
					resolve(true);
				}
			});
		});
	};

	const onRegisterAgain = () => navigate(paths().register);

	return (
		<>
			<Title>Account not yet verified</Title>
			<FadeIn show={apiErrors.length > 0}>
				<ApiErrors errors={apiErrors} />
			</FadeIn>
			<div className={styles.description}>
				<p>Your email address has not been verified yet.</p>
				<p>
					We have sent an email to <strong>{email}</strong> to verify your
					account.
				</p>
			</div>
			<Button inline centered onClick={onLogin}>
				I’ve verified my email
			</Button>
			<div className={styles.divider} />
			<p className={styles.notReceivedYet}>Haven't received an email yet? </p>
			<ResendEmail
				email={email}
				variant="verify-email"
				onResend={handleResend}
			/>
			<p className={styles.wrongEmail}>
				Wrong email?{" "}
				<button
					className={styles.registerAgain}
					type="button"
					onClick={onRegisterAgain}
				>
					Register again
				</button>
			</p>
		</>
	);
};

export default NotVerified;
