import type { FormBuilderProps } from "@app/components/form-builder";
import { pathParams, paths } from "@app/constants/paths";
import type {
	ForexQuote,
	GetForexQuote,
	GetForexQuoteForm,
	GetSettlementAccountSingular,
	TransactionDirection,
} from "@app/entities";
import { useGetSettlementAccounts, useSetRecipientId } from "@app/helpers";
import { useGetForexQuoteWebsocket } from "@app/hooks/use-forex-quote-web-socket";
import type { MappedReasons } from "@app/services";
import * as Sentry from "@sentry/browser";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { fetcher } from "@app/fetcher";
import { useAccountManager } from "@app/hooks/use-account-manager";
import { useClientProfile } from "@app/hooks/use-client-profile";
import { useClients } from "@app/hooks/use-clients";
import {
	tempCurrenciesMapping,
	useCurrencies,
} from "@app/hooks/use-currencies";
import { preload } from "swr";
import { CreateQuoteView } from "./create-quote-view";

import { useQuoteOptions } from "@app/hooks/use-quote-options";
import { useSettlementAccountOptions } from "@app/hooks/use-settlement-account-options";
import { formatNumberString } from "@app/utils/format-number-string";
interface QuoteState {
	errors: string[];
	transactionType?: TransactionDirection;
	fxCurrency?: string;
	fxAmount?: string;
	quoteAmount?: string;
	quoteCurrency?: string;
	valueDate?: string;
	paramId?: number;
	forexQuoteData?: ForexQuote;
	rate?: string;
	settlementAccounts?: GetSettlementAccountSingular;
}

const formatCorrespondingAmount = (val: string) =>
	Number.parseFloat(val).toLocaleString("en", {
		maximumFractionDigits: 2,
		minimumFractionDigits: 2,
	});

const CreateQuote = () => {
	const [localMappedReasons, setLocalMappedReasons] = useState<MappedReasons>(
		{},
	);

	const { data: clientProfile } = useClientProfile();
	const { activeClientId } = useClients();
	const { data: accountManager } = useAccountManager();
	const { data: currencies } = useCurrencies();
	const {
		data: settlementAccounts,
		isLoading: isSettlementAccountOptionsLoading,
	} = useSettlementAccountOptions();

	const [quoteOptions, setQuoteOptions] = useQuoteOptions();

	const [state, setState] = useState<QuoteState>({
		errors: [],
		valueDate: quoteOptions?.valueDate,
		transactionType: quoteOptions?.transactionType as TransactionDirection,
		fxAmount: quoteOptions?.fxAmount,
		quoteAmount: quoteOptions?.quoteAmount,
		quoteCurrency: quoteOptions?.quoteCurrency,
		fxCurrency: quoteOptions?.fxCurrency,
		settlementAccounts: quoteOptions?.settlementAccounts,
		paramId: undefined,
	});
	const [showOutOfHoursModal, setShowOutOfHoursModal] = useState(false);

	const params = useParams();
	const paramId = params[pathParams.id];

	const {
		control,
		handleSubmit,
		formState: { errors, isValid },
		setValue,
	} = useForm<GetForexQuoteForm>({
		defaultValues: {
			transactionType: quoteOptions?.transactionType as TransactionDirection,
			fxAmount: quoteOptions?.fxAmount,
			quoteAmount: quoteOptions?.quoteAmount,
			quoteCurrency: quoteOptions?.quoteCurrency,
			fxCurrency: quoteOptions?.fxCurrency,
			settlementAccounts: quoteOptions?.settlementAccounts,
		},
		mode: "onChange",
	});

	const [getSettlementAccounts] = useGetSettlementAccounts();
	const [setRecipientId] = useSetRecipientId();
	const forexQuoteData = useGetForexQuoteWebsocket(activeClientId || 0, state);

	const [isCreatingQuote, setIsCreatingQuote] = useState(false);
	const navigate = useNavigate();

	const onChangeSettlementAccount = (value: number) => {
		const accountToUse = settlementAccounts?.settlement_accounts.find(
			(current) => current.bank_account_id === value,
		);

		const newMappedReasons = {
			...localMappedReasons,
			settlementAccount: undefined,
		};

		const newState = {
			...state,
			settlementAccounts: accountToUse
				? {
						bankAccountId: accountToUse.bank_account_id,
						accountType: accountToUse.account_type,
						accountNumber: accountToUse.account_number,
					}
				: undefined,
		};

		setState(newState);
		setLocalMappedReasons(newMappedReasons);
	};

	const handleChangeFxCurrency = (value: string) => {
		setLocalMappedReasons((state) => ({
			...state,
			fxAmount: undefined,
			quoteAmount: undefined,
			fxCurrency: undefined,
		}));
		setState((state) => {
			let newState = {
				...state,
				fxCurrency: value,
			};

			if (state.quoteCurrency === state.fxCurrency) {
				newState = {
					...state,
					fxCurrency: value,
					quoteCurrency: value,
				};
			}
			return newState;
		});
	};

	const handleChangeFxAmount = (value: string) => {
		if (!value) {
			setState((state) => ({
				...state,
				fxAmount: "",
				quoteAmount: "",
			}));
			setValue("fxAmount", "");
			setValue("quoteAmount", "");
			return;
		}

		const cleanedFxAmount = value.replace(/,/g, "");
		const isValidFxAmount =
			typeof cleanedFxAmount === "string" &&
			!!cleanedFxAmount &&
			!Number.isNaN(Number(cleanedFxAmount));
		if (isValidFxAmount) {
			const fxAmountWithCommas = formatNumberString(cleanedFxAmount);
			const fxAmount = fxAmountWithCommas.replace(/,/g, "");
			setValue("fxAmount", fxAmountWithCommas, { shouldTouch: true });
			setState((state) => ({
				...state,
				quoteCurrency: state.fxCurrency,
				fxAmount,
			}));

			setLocalMappedReasons((localMappedReasons) => ({
				...localMappedReasons,
				fxAmount: undefined,
			}));

			const hasZarAmount =
				forexQuoteData?.value?.amount && forexQuoteData?.value?.rate;

			if (hasZarAmount) {
				const rate = Number.parseFloat(forexQuoteData.value!.rate);
				const zarAmount = (Number.parseFloat(fxAmount || "0") * rate).toFixed(
					2,
				);
				setState((state) => ({
					...state,
					quoteCurrency: state.fxCurrency,
					quoteAmount: zarAmount,
				}));
				setValue("quoteAmount", formatCorrespondingAmount(zarAmount));
				setLocalMappedReasons((localMappedReasons) => ({
					...localMappedReasons,
					quoteAmount: undefined,
				}));
			}
		}
	};

	const handleChangeZarCurrency = (value: string) => {
		if (!value) {
			setState((state) => ({
				...state,
				fxAmount: "",
				quoteAmount: "",
			}));
			setValue("fxAmount", "");
			setValue("quoteAmount", "");
			return;
		}

		const cleanedZarAmount = value.replace(/,/g, "");
		const isValidZarAmount =
			typeof cleanedZarAmount === "string" &&
			!!cleanedZarAmount &&
			!Number.isNaN(Number(cleanedZarAmount));

		if (isValidZarAmount) {
			const zarAmountCommas = formatNumberString(cleanedZarAmount);
			const zarAmount = zarAmountCommas.replace(/,/g, "");
			setState((state) => ({
				...state,
				quoteCurrency: "ZAR",
				quoteAmount: zarAmount,
			}));
			setValue("quoteAmount", zarAmountCommas);

			setLocalMappedReasons((localMappedReasons) => ({
				...localMappedReasons,
				quoteAmount: undefined,
			}));

			const hasFxAmount =
				forexQuoteData?.value?.amount && forexQuoteData?.value?.rate;

			if (hasFxAmount) {
				const rate = Number.parseFloat(forexQuoteData.value!.rate);
				const fxAmount = (Number.parseFloat(zarAmount || "0") / rate).toFixed(
					2,
				);

				setState((state) => ({
					...state,
					quoteCurrency: "ZAR",
					fxAmount: fxAmount,
				}));
				setValue("fxAmount", formatCorrespondingAmount(fxAmount));
				setLocalMappedReasons((localMappedReasons) => ({
					...localMappedReasons,
					fxAmount: undefined,
				}));
			}
		}
	};

	const getTransactionStepValidationErrors = (errors: MappedReasons) => {
		if (!state.fxCurrency) {
			errors.fxCurrency = ["Select a foreign currency"];
			return;
		}
		delete errors["fxCurrency"];

		if (!state.fxAmount) {
			errors.fxAmount = [
				`Please enter a ZAR or ${state.fxCurrency ?? "FX"} amount`,
			];
		} else {
			delete errors.fxAmount;
		}

		if (!state.quoteAmount) {
			errors.quoteAmount = [
				`Please enter a ZAR or ${state.fxCurrency ?? "FX"} amount`,
			];
		} else {
			delete errors.quoteAmount;
		}

		if (!state.settlementAccounts) {
			errors.settlementAccount = ["Please select a Settlement Account"];
		} else {
			delete errors.settlementAccount;
		}
	};

	const getValueDateValidationErrors = (errors: MappedReasons) => {
		if (!state.valueDate) {
			errors.valueDate = ["Please select a Value Date"];
		} else {
			delete errors.valueDate;
		}
	};

	const handleValidate = (
		type: "all" | "transaction" | "value-dates" = "all",
	) => {
		let isValid = true;
		const newMappedReasons: MappedReasons = { ...localMappedReasons };

		if (type === "all" || type === "transaction") {
			getTransactionStepValidationErrors(newMappedReasons);
		}

		if (type === "all" || type === "value-dates") {
			getValueDateValidationErrors(newMappedReasons);
		}

		const hasForexError =
			!forexQuoteData.value ||
			(forexQuoteData.mappedReasons &&
				Object.keys(forexQuoteData.mappedReasons).length > 0);
		if (Object.keys(newMappedReasons).length || hasForexError) {
			isValid = false;
		}

		setLocalMappedReasons(newMappedReasons);
		return isValid;
	};

	const onSaveQuote = async () => {
		const isValid = handleValidate();

		if (state && isValid) {
			const forexQuote: GetForexQuote = {
				fxCurrency: state.fxCurrency || "",
				fxAmount: state.fxAmount || "",
				quoteAmount: state.quoteAmount || "0",
				quoteCurrency: state.quoteCurrency,
				transactionType: state.transactionType,
				valueDate: state.valueDate,
				settlementAccounts: state.settlementAccounts,
			};

			Sentry.addBreadcrumb({
				category: "transaction",
				level: "info",
				message: "Setting quote options",
				data: forexQuote,
			});
			Sentry.setContext("quote-options", forexQuote);
			setQuoteOptions(forexQuote);
			setIsCreatingQuote(true);
			const result = await preload<{
				transaction_id: number | null;
			}>(
				`/transactions/quotes/${forexQuoteData.value?.quoteId}/duplicate-trx/`,
				fetcher,
			);
			setIsCreatingQuote(false);
			navigate(
				paths().confirmPayment({
					duplicateTransactionId: result?.transaction_id,
				}),
			);
		} else {
			setTimeout(() => {
				const errorElems = document.querySelectorAll(".field-error");
				if (errorElems.length > 0) {
					errorElems[0].scrollIntoView({
						behavior: "smooth",
						block: "center",
					});
				}
			}, 200);
		}
	};

	const onOutOfHours = () => navigate(paths().dashboard);

	const onBack = () => {
		setQuoteOptions(undefined);
		navigate(-1);
	};

	const onSelectTransactionType = (value: TransactionDirection) => {
		setState({
			...state,
			transactionType: value,
		});
	};

	const onSelectValueDate = (value: string) => {
		const newMappedReasons = {
			...localMappedReasons,
			valueDate: undefined,
		};
		delete newMappedReasons.valueDate;
		setState({
			...state,
			valueDate: value,
		});
		setLocalMappedReasons(newMappedReasons);
	};

	useEffect(() => {
		if (activeClientId) {
			getSettlementAccounts(activeClientId);
		}
	}, [activeClientId]);

	useEffect(() => {
		if (settlementAccounts?.settlement_accounts.length === 1) {
			const first = settlementAccounts.settlement_accounts[0];
			setState((state) => ({
				...state,
				settlementAccounts: {
					bankAccountId: first.bank_account_id,
					accountType: first.account_type,
					accountNumber: first.account_number,
				},
			}));
		}
	}, [settlementAccounts]);

	useEffect(() => {
		if (forexQuoteData.showOutOfHours) {
			setShowOutOfHoursModal(true);
		}
	}, [forexQuoteData.showOutOfHours]);

	useEffect(() => {
		if (showOutOfHoursModal) return;
		if (!forexQuoteData.value?.rate) return;
		if (state.rate !== forexQuoteData.value.rate) {
			setState({
				...state,
				rate: forexQuoteData.value.rate,
			});
		}
	}, [forexQuoteData.value, showOutOfHoursModal, state]);

	useEffect(() => {
		if (showOutOfHoursModal) return;
		if (!state.quoteAmount && !state.fxAmount) return;
		if (forexQuoteData.value?.amount) {
			if (state.quoteCurrency === state.fxCurrency) {
				const zarAmount = forexQuoteData.value.amount
					.replace(/^\D+/g, "")
					.replace(/,/g, "");
				setValue("quoteAmount", formatCorrespondingAmount(zarAmount));
				setState((state) => ({
					...state,
					quoteAmount: zarAmount,
				}));
			} else {
				const fxAmount = forexQuoteData.value.amount
					.replace(/^\D+/g, "")
					.replace(/,/g, "");
				setValue("fxAmount", formatCorrespondingAmount(fxAmount));

				setState((state) => ({
					...state,
					fxAmount,
				}));
			}
		}
	}, [
		forexQuoteData.value,
		showOutOfHoursModal,
		setValue,
		state.fxAmount,
		state.fxCurrency,
		state.quoteAmount,
		state.quoteCurrency,
	]);

	const mappedReasons = useMemo(() => {
		if (
			forexQuoteData.mappedReasons &&
			Object.keys(forexQuoteData.mappedReasons).length > 0
		) {
			return {
				...localMappedReasons,
				...forexQuoteData.mappedReasons,
			};
		}

		return {
			...localMappedReasons,
		};
	}, [localMappedReasons, forexQuoteData.mappedReasons]);

	useEffect(() => {
		if (paramId) {
			setRecipientId(+paramId);
		} else {
			setRecipientId(undefined);
		}
	}, [paramId]);

	const inputFields: FormBuilderProps.FormInputProps[][] = [
		[
			{
				fieldRowClassName: "w-full",
				className: `py-3 w-full flex flex-grow bg-white rounded-none rounded-r-lg ${
					mappedReasons.quoteAmount ? "border border-red-450" : ""
				}`,
				iconSize: 24,
				name: "quoteAmount",
				inputMode: "decimal",
				theme: "none",
				title: "",
				type: "text",
				value: state.quoteAmount,
				onChange: handleChangeZarCurrency,
			},
		],
	];

	const fxinputFields: FormBuilderProps.FormInputProps[][] = [
		[
			{
				fieldRowClassName: "w-full ",
				className: `py-3 bg-white rounded-none rounded-r-lg fx-input ${
					mappedReasons.fxAmount ? "border border-red-450" : ""
				}`,
				iconSize: 24,
				name: "fxAmount",
				theme: "none",
				inputMode: "decimal",
				title: "",
				type: "text",
				value: state.fxAmount,
				onChange: (event) => handleChangeFxAmount(event),
			},
		],
	];

	const viewProps = {
		errors,
		onBack,
		formControl: control,
		isValid,
		loading: isSettlementAccountOptionsLoading,
		fxCurrency: state.fxCurrency,
		mappedReasons,
		transactionType: state.transactionType,
		title: "createQuote",
		business: clientProfile?.entity_type === "legal_entity",
		forexQuote: forexQuoteData.value,
		valueDate: state.valueDate,
		accountManager,
		inputFields,
		fxinputFields,
		isCreatingQuote,
		quoteOptions: quoteOptions,
		settlementAccounts: settlementAccounts?.settlement_accounts,
		currencies: currencies?.currencies,
		currenciesMapped: currencies?.currency_mapping
			? currencies.currency_mapping.map(tempCurrenciesMapping)
			: [],
		showOutOfHoursModal,
		onCancel: onBack,
		onSaveQuote,
		onSelectTransactionType,
		onSelectValueDate,
		onChangeFxCurrency: handleChangeFxCurrency,
		onOutOfHours,
		onChangeSettlementAccount,
		onValidate: handleValidate,
		state,
		activeClientId,
	};

	return (
		<form
			onSubmit={handleSubmit(() => {
				state.paramId !== undefined;
			})}
			noValidate
		>
			<CreateQuoteView {...viewProps} />
		</form>
	);
};

export default CreateQuote;
