import type { Dropdown } from "primereact/dropdown";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Button } from "@app/components/button";
import { type FormBuilderProps } from "@app/components/form-builder";
import { Typography } from "@app/components/typography";
import { paths } from "@app/constants/paths";
import type {
	DropdownGroupOption,
	DropdownOption,
	UpdatePayment,
} from "@app/entities";
import {
	useCreateApn,
	useUpdatePayment,
	useUpdatePaymentFields,
} from "@app/helpers";
import { useSetCurrentPage } from "@app/hooks/use-set-payment-current-page";
import type { RootState } from "@app/redux";
import type { MappedReasons } from "@app/services";

import { useCurrencies } from "@app/hooks/use-currencies";
import { useMediaQuery } from "@app/hooks/use-media-query";
import AddIcon from "@app/icons/add.svg?react";
import { PaymentDetailsView } from "./payment-details-view";
import { usePayment } from "./use-payment";
import { usePaymentApn } from "./use-payment-apn";
import { type FeeTypeOption, usePaymentOptions } from "./use-payment-options";
import { useTransactionRecipientNames } from "./use-transaction-recipient-names";

import { CountryIcon } from "@app/components/country-icon";
import { IconButton } from "@app/components/icon-button";
import { isPaymentDetailsAddRecipientEnabled } from "@app/constants/feature-flags";
import { tempExchangeDetailsMapping } from "@app/hooks/use-exchange-details";
import { useTransaction } from "@app/hooks/use-transaction";
import { useTransactionId } from "@app/hooks/use-transaction-id";
import { useSubmittedTransactionRedirect } from "../use-submitted-transaction-redirect";
import styles from "./index.module.css";

interface PaymentDetailsState {
	errors?: string[];
	mappedReasons?: MappedReasons;
	selectedRecipient?: DropdownOption;
}

interface FormState {
	feeType?: string;
	issueDate?: Date[];
	paymentReference?: string;
	purposeOfPayment?: string;
	recipient?: number;
	referenceNumber?: string;
}

const DEFAULT_FEE_TYPE = "OUR";

const PaymentDetails = () => {
	const [isSaving, setIsSaving] = useState(false);
	const isMobile = useMediaQuery();
	const {
		control,
		handleSubmit,
		formState: { errors, isValid, isDirty },
		reset,
		getValues,
	} = useForm<FormState>({
		mode: "onChange",
	});
	const [createApn] = useCreateApn();
	const [updatePayment] = useUpdatePayment();
	const [updatePaymentFields] = useUpdatePaymentFields();

	const transactionId = useTransactionId();

	const {
		exchangeDetails,
		paymentStatus,
		isPaymentStatusLoading,
		activePaymentId,
	} = useTransaction(transactionId);

	const navigate = useNavigate();

	const dropdownRef = useRef<Dropdown>(null);

	const { recipientId } = useSelector(
		(rootState: RootState) => rootState.recipients,
	);

	const { data: currencies, isLoading: isCurrenciesLoading } = useCurrencies();

	const [selectedPaymentFeeTypeOption, setSelectedPaymentFeeTypeOption] =
		useState<FeeTypeOption | undefined>();

	const [state, setState] = useState<PaymentDetailsState>({
		errors: undefined,
		mappedReasons: undefined,
		selectedRecipient: undefined,
	});

	const { data: paymentApn, isLoading: isPaymentApnLoading } =
		usePaymentApn(activePaymentId);

	const {
		data: paymentFeeTypeOptions,
		isLoading: isPaymentFeeTypeOptionsLoading,
	} = usePaymentOptions(activePaymentId);

	const {
		data: payment,
		isLoading: isPaymentLoading,
		error: paymentError,
		mutate,
	} = usePayment(activePaymentId);

	const {
		data: transactionRecipientNames,
		isLoading: isTransactionRecipientNamesLoading,
		error: transactionRecipientNamesError,
	} = useTransactionRecipientNames(
		exchangeDetails?.direction === "send"
			? exchangeDetails?.transaction_id
			: undefined,
	);

	useEffect(() => {
		if (paymentError || transactionRecipientNamesError) {
			navigate(
				paths().error.generalError({
					paymentError,
					transactionRecipientNamesError,
				}),
			);
		}
	}, [paymentError, transactionRecipientNamesError, navigate]);

	useEffect(() => {
		if (payment) {
			const values = getValues();
			reset({
				...values,
				paymentReference: values.paymentReference ?? payment.payment_reference,
				purposeOfPayment: values.purposeOfPayment ?? payment.purpose_of_payment,
				recipient: values.recipient ?? recipientId ?? payment.recipient_id,
			});
		}
	}, [payment, recipientId]);

	useEffect(() => {
		if (paymentApn) {
			const values = getValues();
			reset({
				...values,
				referenceNumber: values.referenceNumber ?? paymentApn.reference_number,
				issueDate:
					(values.issueDate ?? paymentApn.issue_date)
						? [new Date(paymentApn.issue_date)]
						: undefined,
			});
		}
	}, [paymentApn]);

	const onBack = () => {
		if (exchangeDetails) {
			navigate(
				paths().confirmPayment({
					id: exchangeDetails.transaction_id,
				}),
			);
		} else {
			navigate(paths().error.generalError());
		}
	};

	const onClearError = () => {
		setState({ ...state, errors: [] });
	};

	const onNavigateTransaction = (navigationPath: string) => {
		handleSubmit((data) => onHandleSubmitValid(data, navigationPath))();
	};

	const onChangeSelectedPaymentFeeTypeOption = useCallback(
		(value?: FeeTypeOption[]) => {
			let paymentFeeTypeOption: FeeTypeOption | undefined = undefined;

			if (exchangeDetails) {
				if (value) {
					if (value.length > 0) {
						paymentFeeTypeOption = value[0];
					}
				}

				setSelectedPaymentFeeTypeOption(paymentFeeTypeOption);
				setIsSaving(true);
				updatePaymentFields(
					{
						paymentId: exchangeDetails?.payment_ids[0],
						feeType: paymentFeeTypeOption?.fee_type,
					},
					(errors?) => {
						setIsSaving(false);
						if (errors) {
							navigate(paths().error.generalError(errors));
						} else {
							mutate();
						}
					},
				);
			} else {
				navigate(paths().error.generalError());
			}
		},
		[exchangeDetails, mutate, navigate, updatePaymentFields],
	);

	const handleResumeLater = async () => {
		return new Promise((resolve) => {
			const values = getValues();
			updatePaymentFields(
				{
					paymentId: exchangeDetails?.payment_ids[0],
					feeType: selectedPaymentFeeTypeOption?.fee_type,
					paymentReference: values.paymentReference,
					purposeOfPayment: values.purposeOfPayment,
					recipient: values.recipient,
				},
				() => {
					resolve();
					mutate();
				},
			);
		}) as Promise<void>;
	};

	const onHandleSubmitValid = (data: FormState, navigationPath?: string) => {
		if (!transactionId) return null;
		const dataAsPayment: UpdatePayment = {
			feeType: selectedPaymentFeeTypeOption?.fee_type,
			paymentReference: data.paymentReference,
			purposeOfPayment: data.purposeOfPayment,
			recipient: data.recipient,
		};

		const nextNavigationPath =
			navigationPath ?? paths().balanceOfPayment(transactionId);

		if (exchangeDetails) {
			updatePayment(
				{
					paymentId: exchangeDetails?.payment_ids[0],
					payment: dataAsPayment,
				},
				(errors?: string[], mappedReasons?: MappedReasons) => {
					if (
						!errors ||
						(errors.length === 0 && !mappedReasons && !mappedReasons)
					) {
						if (paymentFeeTypeOptions?.display_apn_details) {
							if (data.referenceNumber || data.issueDate) {
								createApn(
									{
										apn: {
											issueDate: data.issueDate ? data.issueDate[0] : undefined,
											referenceNumber: data.referenceNumber
												? data.referenceNumber
												: undefined,
										},
										paymentId: exchangeDetails?.payment_ids[0],
									},
									(apnErrors?: string[], apnMappedReasons?: MappedReasons) => {
										if (
											!apnErrors ||
											(apnErrors?.length === 0 &&
												!apnMappedReasons &&
												!apnMappedReasons)
										) {
											navigate(nextNavigationPath);
										} else {
											setState({
												...state,
												mappedReasons: apnMappedReasons,
											});
										}
									},
								);
							} else {
								navigate(nextNavigationPath);
							}
							mutate();
						} else {
							navigate(nextNavigationPath);
						}
					} else {
						setState({
							...state,
							mappedReasons,
						});
						navigate(paths().error.generalError(mappedReasons));
					}
				},
			);
		} else {
			navigate(paths().error.generalError());
		}
	};

	const listedRecipientNamesAsGroupedOptions: DropdownGroupOption[] = [
		{
			label: transactionRecipientNames?.shortlist.label,
			value: transactionRecipientNames?.shortlist.currency,
			items: transactionRecipientNames?.shortlist.list
				? transactionRecipientNames?.shortlist.list.map((x) => {
						return {
							label: x.name,
							value: x.id,
						};
					})
				: [],
		},
		{
			label: transactionRecipientNames?.other_recipients.label,
			value: transactionRecipientNames?.other_recipients.currency,
			items: transactionRecipientNames?.other_recipients.list
				? transactionRecipientNames?.other_recipients.list.map((x) => {
						return {
							label: x.name,
							value: x.id,
						};
					})
				: [],
		},
	];

	const paymentPurposeField: FormBuilderProps.FormInputProps = {
		className:
			"payment-details-input py-3 px-3 border border-gray-120 bg-white rounded-4px",
		iconSize: 24,
		name: "purposeOfPayment",
		placeholder: "Enter a purpose of payment",
		required: true,
		showLabel: true,
		theme: "none",
		title: "Purpose of Payment",
		type: "text",
		onBlur: () => {
			handleAutoSave();
		},
		onChange: () => {
			onClearError();
		},
	};

	const apnReferenceField: FormBuilderProps.FormInputProps = {
		className:
			"payment-details-input py-3 px-3 border border-gray-120 bg-white rounded-4px",
		iconSize: 24,
		name: "referenceNumber",
		disabled: paymentApn && !paymentApn.is_editable,
		placeholder: "Reference Number",
		required: false,
		showLabel: false,
		theme: "none",
		title: "Reference Number (optional)",
		type: "text",
		onBlur: () => {
			handleAutoSave();
		},
		onChange: () => {
			onClearError();
		},
	};

	const apnIssueDate: FormBuilderProps.FormInputProps = {
		appendToParent: true,
		name: "issueDate",
		placeholder: "Issue Date",
		required: false,
		showLabel: false,
		disabled: paymentApn && !paymentApn.is_editable,
		title: "Issue Date (optional)",
		type: "date-picker",
		maxDate: new Date(),
		panelClassName: "issue-date-field",
		className: "issue-date",
		onChange: () => {
			onClearError();
			handleAutoSave();
		},
	};

	const apnInputFields: FormBuilderProps.FormInputProps[][] = isMobile
		? [[apnReferenceField], [apnIssueDate]]
		: [[apnReferenceField, apnIssueDate]];

	const paymentReferenceField: FormBuilderProps.FormInputProps = {
		className:
			"payment-details-input py-3 px-3 border border-gray-120 bg-white rounded-4px",
		iconSize: 24,
		name: "paymentReference",
		placeholder: "Enter a payment reference",
		required: false,
		showLabel: true,
		theme: "none",
		title: "Payment Reference (optional)",
		type: "text",
		onBlur: () => {
			handleAutoSave();
		},
		onChange: () => {
			onClearError();
		},
	};

	const handleAddRecipient = () => {
		if (!transactionId) return;
		navigate(
			isPaymentDetailsAddRecipientEnabled
				? paths().paymentDetailsAddRecipient(transactionId)
				: paths().addRecipient,
		);
	};

	const recipientField: FormBuilderProps.FormInputProps = {
		filter: true,
		className: "w-full rounded-4px py-0",
		dropdownRef: dropdownRef,
		name: "recipient",
		options: listedRecipientNamesAsGroupedOptions ?? [],
		placeholder: "Select a recipient",
		required: true,
		showLabel: true,
		title: "Recipient",
		type: "dropdown-option-grouped",
		actions: isMobile ? (
			<IconButton onClick={handleAddRecipient} variant="primary">
				<AddIcon width={20} height={20} />
			</IconButton>
		) : (
			<Button variant="secondary" onClick={handleAddRecipient}>
				<AddIcon width={20} height={20} />
				New
			</Button>
		),
		panelClassName: styles.recipientDropdown,
		optionGroupTemplate: (option) => {
			const currency = currencies?.currency_mapping.find(
				(x) => x.currency_code === option.value?.toString(),
			);
			return (
				<div className="flex flex-row gap-x-3">
					{currency !== undefined && (
						<CountryIcon
							width={24}
							height={24}
							currencyCode={currency.currency_code}
						/>
					)}
					<Typography className="font-semibold text-gray-1100" theme="textLg">
						{option.label}
					</Typography>
				</div>
			);
		},

		onChange: () => {
			onClearError();
			handleAutoSave();
		},
	};

	const receiveInputFields: FormBuilderProps.FormInputProps[][] = isMobile
		? [[paymentPurposeField], [paymentReferenceField]]
		: [[paymentPurposeField, paymentReferenceField]];

	const sendInputFields: FormBuilderProps.FormInputProps[][] = isMobile
		? [[recipientField], [paymentPurposeField], [paymentReferenceField]]
		: [
				[recipientField, paymentReferenceField],
				[
					paymentPurposeField,
					{
						title: "",
						name: "",
						type: "spacer",
					},
				],
			];

	useSubmittedTransactionRedirect();
	useSetCurrentPage(exchangeDetails?.payment_ids[0], "payment_details");

	const handleAutoSave = () => {
		setIsSaving(true);
		const values = getValues();
		updatePaymentFields(
			{
				paymentId: exchangeDetails?.payment_ids[0],
				recipient: recipientId,
				feeType: selectedPaymentFeeTypeOption?.fee_type,
				paymentReference: values.paymentReference,
				purposeOfPayment: values.purposeOfPayment,
			},
			(errors) => {
				setIsSaving(false);
			},
		);
	};

	useEffect(() => {
		if (recipientId) {
			const values = getValues();

			updatePaymentFields(
				{
					paymentId: exchangeDetails?.payment_ids[0],
					recipient: recipientId,
				},
				() => {
					mutate();
				},
			);

			reset({
				...values,
				recipient: recipientId,
			});
		}
	}, []);

	const isLoading =
		isCurrenciesLoading ||
		isPaymentLoading ||
		isPaymentFeeTypeOptionsLoading ||
		isPaymentStatusLoading ||
		isTransactionRecipientNamesLoading ||
		isPaymentApnLoading;

	useEffect(() => {
		if (
			!payment ||
			!paymentFeeTypeOptions?.allowed_fee_types ||
			isPaymentLoading ||
			selectedPaymentFeeTypeOption
		) {
			return;
		}

		const defaultFeeTypeOption = paymentFeeTypeOptions.allowed_fee_types.find(
			(current) => current.fee_type === DEFAULT_FEE_TYPE,
		);
		if (!payment.fee_type && defaultFeeTypeOption) {
			onChangeSelectedPaymentFeeTypeOption([defaultFeeTypeOption]);
			return;
		}

		const feeTypeOption = paymentFeeTypeOptions.allowed_fee_types.find(
			(current) =>
				current.fee_type.toLowerCase() === payment.fee_type?.toLowerCase(),
		);

		if (feeTypeOption) {
			onChangeSelectedPaymentFeeTypeOption([feeTypeOption]);
		}
	}, [
		paymentFeeTypeOptions,
		selectedPaymentFeeTypeOption,
		onChangeSelectedPaymentFeeTypeOption,
		payment,
		isPaymentLoading,
	]);

	return (
		<form
			onSubmit={handleSubmit((data) => onHandleSubmitValid(data))}
			noValidate
		>
			<PaymentDetailsView
				displayApnDetails={paymentFeeTypeOptions?.display_apn_details}
				apnInputFields={
					paymentFeeTypeOptions?.display_apn_details ? apnInputFields : []
				}
				exchangeDetails={tempExchangeDetailsMapping(exchangeDetails)}
				mappedReasons={state.mappedReasons}
				selectedPaymentFeeTypeOption={selectedPaymentFeeTypeOption}
				selectedRecipient={state.selectedRecipient}
				isDirty={isDirty}
				isValid={isValid}
				errors={errors}
				formControl={control}
				paymentStatus={paymentStatus}
				paymentFeeTypeOptions={paymentFeeTypeOptions}
				title="paymentDetails"
				onBack={onBack}
				loading={isLoading}
				payment={payment}
				inputFields={
					exchangeDetails
						? exchangeDetails.direction === "receive"
							? receiveInputFields
							: sendInputFields
						: []
				}
				isSaving={isSaving}
				onResumeLater={handleResumeLater}
				onNavigateTransaction={onNavigateTransaction}
				onChangeSelectedPaymentFeeTypeOption={
					onChangeSelectedPaymentFeeTypeOption
				}
			/>
		</form>
	);
};

export default PaymentDetails;
